import React from 'react';

import './hero-products.scss';
import { Link } from 'react-router-dom';


interface Props {
	title?: string;
	backgroundImg?: string;
}

const ServicesHeroProducts: React.FC<Props> = ({ title, backgroundImg }) => {
	let divStyle = { backgroundImage: 'url(' + backgroundImg + ')' };
	return (
		<div className={`service-hero-products-container`}>
			<div className={`service-hero-products-content`} style={divStyle}>
					<div className='inner ct-container'>
						<div>
							<Link className="service-hero-products-back-btn" to="/servicios">
								<i className="service-hero-icon service-hero-icon-left-arrow"></i>
								SERVICIOS
							</Link>
						</div>

						<div className="service-hero-bottom">
							<h1>{title}</h1>
						</div>
					</div>
			</div>
		</div>
	);
};

export default ServicesHeroProducts;
