import React, { ReactNode } from 'react';

import './radio-button.scss';

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	label?: string;
	pricePerc?: number;
	priceDiscount?: string;
	checkedStatus?: boolean;
	handleClick?: any;
}

const RadioButton: React.FC<IProps> = ({
	children,
	className,
	label,
	price,
	pricePerc,
	checkedStatus,
	handleClick,
	priceDiscount,
}) => (
	<div className={`radio-button ${className}`} onClick={handleClick}>
		<div className="radio">
			<div className="radio-indicator"></div>
			<div className="radio-label">{label}</div>
		</div>
		{price ? <div className="radio-qty ">${price}</div> : ''}
		{pricePerc ? (
			<div className="radio-discount">
				<strong>{pricePerc}%</strong> de descuento
			</div>
		) : (
			''
		)}
		{priceDiscount && !['0.00', '0', '0.0'].includes(priceDiscount) && (
			<div className="radio-discount">
				Descuento $<strong>{priceDiscount}</strong>
			</div>
		)}
		{children}
	</div>
);

export default RadioButton;
