import React, { ReactNode, useContext, useRef } from 'react';
import './header.scss';
import { AppContext } from 'helpers/Context';

import CloseBtn from '../../../images/svg/close-btn.svg';
import { Link, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalState';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getBranchUrl } from 'helpers/Convertions';

const MySwal = withReactContent(Swal);

interface IProps {
	children?: ReactNode;
	className?: string;
	price?: string;
	progress?: string;
}

const FunnelHeader: React.FC<IProps> = ({ className, progress }) => {
	const { reset } = useContext(GlobalContext);
	const { branch } = useContext(AppContext);
	const hrefRedirect = useRef(null);

	const closeBtnClick = () => {
		reset('');
	};

	let history = useHistory();
	const onCloseFunnel = () => {
		MySwal.fire({
			title: '¿Realmente desea salir? La suscripción no se activa hasta que los datos estén completados',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: `Continuar aquí`,
			denyButtonText: `Salir`,
			customClass: {
				confirmButton: 'button button--orange perfil-vehiculo-orange-btn mobile btnAlert',
				denyButton: 'button-disabled btnAlert',
			},
			buttonsStyling: false,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
			} else if (result.isDenied) {
				(hrefRedirect.current as any).click();
				return;
			}
		});
	};
	return (
		<div>
			<div className="funnel-header-content desktop">
				<div className="funnel-header-content-left">
					<a className="cobertura-link" href={`${getBranchUrl(branch)}`} rel="noopener noreferrer" ref={hrefRedirect}>
						<span className="funnel-header-content-left-logo" />
					</a>
				</div>
				<div className="funnel-header-content-right">
					<Link onClick={onCloseFunnel} to="#">
						<img className="funnel-header-content-right-close-btn" src={CloseBtn} onClick={closeBtnClick} alt="" />
					</Link>
				</div>
			</div>
			<div className={`funnel-header-container ${className}`}>
				<div className={`funnel-header-progress funnel-header-progress-${progress}`}></div>
			</div>
		</div>
	);
};

export default FunnelHeader;
