import api from './Api';

const getMakeService = async (year: string) => {
	return await api.get(`/utils/getMake/${year}`);
};

const getModelService = async (year: string, make: string) => {
	return await api.get(`/utils/getModel/${year}/${make}`);
};

export { getMakeService, getModelService };
