import React from 'react';

import './nuestra-garantia.scss';

import img_247 from '../../../images/services/24-7.png';
import img_99 from '../../../images/services/99_percent.png';
import img_clock from '../../../images/services/clock.png';
import { useTranslation } from 'react-i18next';

interface Props {}

const ServiceNuestraGarantia: React.FC<Props> = () => {
	const { t } = useTranslation();

	return (
		<div className="nuestra-garantia text-center">
			<div className="container">
				<h2 className="orange-title">Nuestra Garantía</h2>

				<div className="nuestra-garantia__cols">
					<div>
						<img src={img_247} alt="24/7 Support" />
						<h3>Centro de llamadas disponible 24/7</h3>
						<p>
							En Connect contamos con centros de llamadas en Puerto Rico, Costa Rica, Panamá y Colombia disponibles a toda hora.
							Estamos contigo cuando nos necesites.
						</p>
					</div>
					<div>
						<img src={img_clock} alt="26 minutes on stopwatch" />
						<h3>Primeros en tiempo de respuesta: {t('tiempo')} mins</h3>
						<p>
							Más que una estadística, es un reto que intentamos superar cada día. Trabajamos sin descanso por mejorar
							nuestro tiempo de respuesta para estar contigo cuanto antes.
						</p>
					</div>
					<div>
						<img src={img_99} alt="99%" />
						<h3>99% de clientes satisfechos</h3>
						<p>
							Tu satisfacción es nuestro mayor motivador y ofrecer una solución extraordinaria a tus problemas
							ordinarios nuestra misión.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ServiceNuestraGarantia;
