import moment from 'moment';
import { getVehicleFormCountry } from './Convertions';

const getYears = (count: number) => {
	let years: any = [];
	const thisYear: number = parseInt(moment().format('YYYY'));
	for (let index = thisYear; index < thisYear + count; index++) {
		years.push({
			value: `${index}`,
			label: `${index}`,
		});
	}

	return years;
};

const getPastYears = (count: number) => {
	let years: any = [];
	const thisYear: number = parseInt(
		moment()
			.subtract(count - 2, 'years')
			.format('YYYY')
	);
	for (let index = thisYear; index < thisYear + count; index++) {
		years.push({
			value: `${index}`,
			label: `${index}`,
		});
	}

	return years;
};

const motoMake = [
	{
		value: 'Ape',
		label: 'Ape',
	},
	{
		value: 'Aprilia',
		label: 'Aprilia',
	},
	{
		value: 'Ariel',
		label: 'Ariel',
	},
	{
		value: 'Armstrong',
		label: 'Armstrong',
	},
	{
		value: 'ATK',
		label: 'ATK',
	},
	{
		value: 'BMW',
		label: 'BMW',
	},
	{
		value: 'Bajaj',
		label: 'Bajaj',
	},
	{
		value: 'Beneli',
		label: 'Beneli',
	},
	{
		value: 'Can Am',
		label: 'Can Am',
	},
	{
		value: 'CF Moto',
		label: 'CF Moto',
	},
	{
		value: 'Custom Built',
		label: 'Custom Built',
	},
	{
		value: 'Ducati',
		label: 'Ducati',
	},
	{
		value: 'Formula',
		label: 'Formula',
	},
	{
		value: 'Freedom',
		label: 'Freedom',
	},
	{
		value: 'Harley',
		label: 'Harley',
	},
	{
		value: 'Honda',
		label: 'Honda',
	},
	{
		value: 'Kawasaki',
		label: 'Kawasaki',
	},
	{
		value: 'KTM',
		label: 'KTM',
	},
	{
		value: 'Katana',
		label: 'Katana',
	},
	{
		value: 'Moto Guzzi',
		label: 'Moto Guzzi',
	},
	{
		value: 'Niu',
		label: 'Niu',
	},
	{
		value: 'Polaris',
		label: 'Polaris',
	},
	{
		value: 'Piaggio',
		label: 'Piaggio',
	},
	{
		value: 'Royal Enfield',
		label: 'Royal Enfield',
	},
	{
		value: 'Serpento',
		label: 'Serpento',
	},
	{
		value: 'SYM',
		label: 'SYM',
	},
	{
		value: 'Suzuki',
		label: 'Suzuki',
	},
	{
		value: 'Triumph',
		label: 'Triumph',
	},
	{
		value: 'Yamaha',
		label: 'Yamaha',
	},
	{
		value: 'Zero',
		label: 'Zero',
	},
	{
		value: 'Otro',
		label: 'Otro',
	},
];

const buildPerfilArray = (data: any, branch: string = '') => {
	const perfilArray: any = [];
	let key = 1;
	if (data.auto > 0) {
		for (let index = 0; index < data.auto; index++) {
			perfilArray.push({
				display: key === 1 ? true : false,
				key: key++,
				h3: 'Completa la información de tu auto',
				title: `Auto `,
				section: 'auto',
			});
		}
	}
	if (data.hogar > 0) {
		for (let index = 0; index < data.hogar; index++) {
			perfilArray.push({
				display: key === 1 ? true : false,
				key: key++,
				h3: 'Completa la información de tu hogar',
				title: `Hogar `,
				section: 'home',
			});
		}
	}
	if (data.motora > 0) {
		for (let index = 0; index < data.motora; index++) {
			perfilArray.push({
				display: key === 1 ? true : false,
				key: key++,
				h3: `Completa la información de tu ${getVehicleFormCountry(branch)}`,
				title: `Motora `,
				section: 'motora',
			});
		}
	}
	return perfilArray;
};

const getCardMonths = () => {
	let getMonth: any = [];
	for (let index = 1; index < 13; index++) {
		getMonth.push({
			value: index,
			label: index,
		});
	}
	return getMonth;
};

// Esto se utiliza para popular la cantidad de Cuartos y Banos
const hogarCantidades = () => {
	let getMonth: any = [];
	for (let index = 1; index < 6; index++) {
		getMonth.push({
			value: index,
			label: index,
		});
	}
	return getMonth;
};

const tipoPropiedad = [
	{
		value: '',
		label: '',
	},
	{
		value: 'Casa',
		label: 'Casa',
	},
	{
		value: 'Apartamento',
		label: 'Apartamento',
	},
	{
		value: 'Walk-Up',
		label: 'Walk-Up',
	},
	{
		value: 'Campo',
		label: 'Campo',
	},
];

export { getYears, getPastYears, motoMake, buildPerfilArray, getCardMonths, hogarCantidades, tipoPropiedad };
