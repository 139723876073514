import React from 'react';

import './header.scss';
import HeaderDesktop from './desktop/header-desktop';
import HeaderMobile from './mobile/header-mobile';
import { AppContext } from '../../helpers/Context';

export default function Header() {
	return (
		<AppContext.Consumer>
			{({ showStatus }) => {
				if (showStatus) {
					return (
						<div>
							<HeaderDesktop />
							<HeaderMobile />
						</div>
					);
				}
				return '';
			}}
		</AppContext.Consumer>
	);
}
