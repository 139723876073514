import React, { useContext, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './funnel-footer-mobile.scss';
import RightArrow from '../../../../images/svg/right-arrow.svg';
import CloseBtn from '../../../../images/svg/close-btn.svg';
import GrayLeftArrow from '../../../../images/svg/gray-left-arrow.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from 'helpers/Context';
import { getBranchUrl } from 'helpers/Convertions';

const MySwal = withReactContent(Swal);
interface IProps {
	className?: string;
	section?: string;
	active?: string;
	btnLabel?: string;
	linkTo?: string;
	classNameBtn?: string;
	showChevron?: boolean;
	totalScreen?: number;
	currentScreen?: number;
	messagePayment?: boolean;
}

const FunnelFooterMobile: React.FC<IProps> = ({
	className,
	section,
	active,
	showChevron,
	totalScreen,
	currentScreen,
}) => {
	let history = useHistory();
	const { branch } = useContext(AppContext);
	const hrefRedirect = useRef(null);
	const handleClose = () => {
		if (active === 'cobertura') {
			(hrefRedirect.current as any).click();
		} else {
			history.goBack();
		}
	};
	const onCloseFunnel = () => {
		MySwal.fire({
			title: '¿Realmente desea salir? La suscripción no se activa hasta que los datos estén completados',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: `Continuar aquí`,
			denyButtonText: `Salir`,
			customClass: {
				confirmButton: 'button button--orange perfil-vehiculo-orange-btn mobile btnAlert',
				denyButton: 'button-disabled btnAlert',
			},
			buttonsStyling: false,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
			} else if (result.isDenied) {
				(hrefRedirect.current as any).click();
				return;
			}
		});
	};

	return (
		<div className={`${className}`}>
			<div className={`funnel-footer-mobile-container`}>
				<div className="funnel-footer-mobile-info-div">
					{!showChevron && (
						<Link className="mobile" to="#" onClick={handleClose}>
							<img className="funnel-footer-mobile-left-arrow pointer" src={GrayLeftArrow} alt="" />
						</Link>
					)}
					{section === 'steps' ? (
						<ul className="funnel-footer-mobile-ul">
							<li className={active === 'cobertura' ? 'funnel-footer-mobile-active' : ''}>PLANES</li>
							<li>
								<img src={RightArrow} alt="" />
							</li>
							<li className={active === 'info' ? 'funnel-footer-mobile-active' : ''}>INFO</li>
							<li>
								<img src={RightArrow} alt="" />
							</li>
							<li className={active === 'pago' ? 'funnel-footer-mobile-active' : ''}>PAGO</li>
						</ul>
					) : (
						<ul className="funnel-footer-mobile-ul">
							<li className="funnel-footer-mobile-active">{section}</li>
						</ul>
					)}
				</div>

				{section === 'Completar Perfil' && (
					<div className="funnel-footer-mobile-tracking">
						{currentScreen}
						<span className="tracking-divider">/</span>
						{totalScreen}
					</div>
				)}
				<Link onClick={onCloseFunnel} to="#" className="funnel-footer-mobile-close-btn-link">
					<img className="funnel-footer-mobile-close-btn mobile" src={CloseBtn} alt="" />
				</Link>
			</div>
			<a
				style={{ display: 'none', height: '0' }}
				href={`${getBranchUrl(branch)}`}
				rel="noopener noreferrer"
				ref={hrefRedirect}
			></a>
		</div>
	);
};

export default FunnelFooterMobile;
