import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';

import { AppContext } from 'helpers/Context';
import { GlobalContext } from 'context/GlobalState';
import { FunnelData, TotalPrice } from 'interfaces/FunnelInterface';

import './cobertura.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';
import RadioButton from 'components/inputs/radio-button/radio-button';
import CarIcon from 'images/home/servicios/icon-auto-connect-blue.png';
import HomeIcon from 'images/home/servicios/icon-hogar-connect-blue.png';
import MotoIcon from 'images/home/servicios/icon-motora-connect-blue.png';
import FunnelHeader from 'components/funnel/header/header';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';

import DoneIcon from '@material-ui/icons/Done';
import Pricing from 'helpers/Pricing';
import Toaster from 'helpers/Toaster';
import GoogleTagManager from 'helpers/GoogleTagManager';
import { createLead } from 'services/LeadService';
import { FormControlLabel } from '@material-ui/core';
import { getBranchUrl, getVehicleFormCountry } from 'helpers/Convertions';
import { Helmet } from 'react-helmet';
import { defaultBranch } from 'helpers/Constants';
import TagManager from 'react-gtm-module';

const FunnelCobertura = () => {
	const history = useHistory();
	const { setShowStatus, branch, identify, setIdentifyStatus, setBranchStatus } = useContext(AppContext);
	const { funnel, setFunnel } = useContext(GlobalContext);
	const [funnelData, setFunnelData] = useState<FunnelData>(funnel);
	const [vehicleCount, setVehicleCount] = useState(funnelData.step1.cobertura.auto);
	const [motoCount, setMotoCount] = useState(funnelData.step1.cobertura.motora);
	const [homeCount, setHomeCount] = useState(funnelData.step1.cobertura.hogar);
	const [pago, setPago] = useState(funnelData.step1.pago);
	const [total, setTotal] = useState<TotalPrice>(funnelData.total);
	const [checkVehicle, setCheckedVehicle] = useState(true);
	const [checkedMoto, setCheckedMoto] = useState(true);
	const googleTag = GoogleTagManager;
	const year = new Date().getFullYear() - (branch === 'pr' ? 15 : 22);
	const [branchQP, setBranchQP] = useState<any>(null);
	let { search } = useLocation();
	const paramsString = decodeURIComponent(search);
	let params = new URLSearchParams(paramsString);

	useEffect(() => {
		setBranchQP(params.get('country'));
		setBranchStatus(branchQP);
		console.log('BRANCHQP => ', params.get('country'));
		console.log('BRANCHQPSETER ', branchQP);
	}, [branchQP]);

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		googleTag.planPage(funnelData.step1.plan);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [googleTag]);

	const calculate = (country) => {
		const data = {
			auto: vehicleCount,
			moto: motoCount,
			home: homeCount,
			pago: pago,
			branch: country,
		};
		setTotal(Pricing.calculate(data));
	};

	const calculateWithoutHook = async (
		vehicle: number,
		moto: number,
		home: number,
		anualMensual: string,
		branch: string
	) => {
		const data = {
			auto: vehicle,
			moto: moto,
			home: home,
			pago: anualMensual,
			branch: branch,
		};
		return Pricing.calculate(data);
	};

	useEffect(() => {
		setShowStatus(false);

		if (pago) {
			const country = getBranch();
			calculate(country);
		}
		return () => {
			setShowStatus(true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setShowStatus, pago, vehicleCount, motoCount, homeCount]);

	useEffect(() => {
		const fetchFunnelId = async () => {
			const getFunnelId = await createLead(branch.toUpperCase(), funnel.utm);
			funnelData.subscriptionData.leadMongoId = getFunnelId.data.data.funelId;
			setFunnelData(funnelData);
		};
		console.log(funnel);
		fetchFunnelId();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funnelData]);

	const handleVehicleChange = async (type: string) => {
		switch (type) {
			case 'plus':
				if (branch === 'co') {
					if (vehicleCount < 5 && pago === 'mensual') {
						setVehicleCount(vehicleCount + 1);
					}
					if (vehicleCount < 3 && pago === 'anual') {
						setVehicleCount(vehicleCount + 1);
					}
				} else if (vehicleCount < 5) {
					setVehicleCount(vehicleCount + 1);
				}
				const tmpTotal = await calculateWithoutHook(vehicleCount + 1, motoCount, homeCount, pago, branch);
				await calculateFunnel(vehicleCount + 1, motoCount, homeCount, pago, tmpTotal);
				break;
			case 'minus':
				if (vehicleCount > 0) {
					setVehicleCount(vehicleCount - 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount - 1, motoCount, homeCount, pago, branch);
					await calculateFunnel(vehicleCount - 1, motoCount, homeCount, pago, tmpTotal);
				}
				break;
		}
	};
	const handlePago = async (type: string) => {
		let quantity = 0;
		setPago(type);
		if (branch === 'co' && pago === 'mensual' && vehicleCount > 3) {
			quantity = 3;
			setVehicleCount(quantity);
			const tmpTotal = await calculateWithoutHook(quantity, 0, 0, pago, branch);
			await calculateFunnel(quantity, 0, 0, pago, tmpTotal);
		}
	};

	const handleMotoChange = async (type: string) => {
		switch (type) {
			case 'plus':
				if (motoCount < 5) {
					setMotoCount(motoCount + 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount, motoCount + 1, homeCount, pago, branch);
					await calculateFunnel(vehicleCount, motoCount + 1, homeCount, pago, tmpTotal);
				}
				break;
			case 'minus':
				if (motoCount > 0) {
					setMotoCount(motoCount - 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount, motoCount - 1, homeCount, pago, branch);
					await calculateFunnel(vehicleCount, motoCount - 1, homeCount, pago, tmpTotal);
				}
				break;
		}
	};

	const handleHomeChange = async (type: string) => {
		switch (type) {
			case 'plus':
				if (homeCount < 5) {
					setHomeCount(homeCount + 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount, motoCount, homeCount + 1, pago, branch);
					await calculateFunnel(vehicleCount, motoCount, homeCount + 1, pago, tmpTotal);
				}

				break;
			case 'minus':
				if (homeCount > 0) {
					setHomeCount(homeCount - 1);
					const tmpTotal = await calculateWithoutHook(vehicleCount, motoCount, homeCount - 1, pago, branch);
					await calculateFunnel(vehicleCount, motoCount, homeCount - 1, pago, tmpTotal);
				}
				break;
		}
	};

	const calculateFunnel = async (vehicle: number, moto: number, home: number, montlyAnual: string, tmpTotal: any) => {
		const countryPlanIdentifier = branch === 'co' ? '-co' : '';
		const planSelected =
			vehicle + '-' + moto + '-' + home + '-' + montlyAnual.charAt(0).toUpperCase() + countryPlanIdentifier;

		const data = {
			cobertura: {
				auto: vehicle,
				motora: moto,
				hogar: home,
			},
			pago: montlyAnual,
			plan: planSelected,
		};

		funnelData.total = tmpTotal;
		funnelData.step1 = data;

		setFunnelData(funnelData);
		setFunnel(funnelData);

		const amountTotal = getTotal();

		window.analytics.track('Product Added', {
			product_id: planSelected,
			name: planSelected,
			price: amountTotal,
			currency: branch === 'co' ? 'cop' : 'usd',
			value: amountTotal,
		});
	};

	const handleContinuarBtn = () => {
		const countryPlanIdentifier = branch === 'co' ? '-co' : '';
		const planSelected =
			vehicleCount + '-' + motoCount + '-' + homeCount + '-' + pago.charAt(0).toUpperCase() + countryPlanIdentifier;
		const cubiertaTotal = vehicleCount + motoCount + homeCount;

		if (cubiertaTotal === 0) {
			Toaster.error('Para continuar, escoja un plan');
			return;
		}

		if (vehicleCount > 0) {
			if (!checkVehicle) {
				Toaster.error(`Por favor añadir propiedades del ${year}+ para continuar`);
				return;
			}
		}

		if (motoCount > 0) {
			if (!checkedMoto) {
				Toaster.error(`Por favor añadir propiedades del ${year}+ para continuar`);
				return;
			}
		}

		const data = {
			cobertura: {
				auto: vehicleCount,
				motora: motoCount,
				hogar: homeCount,
			},
			pago: pago,
			plan: planSelected,
		};

		funnelData.total = total;
		funnelData.step1 = data;
		const amountTotal = getTotal();
		window.analytics.track('Product Added', {
			product_id: funnel.step1.plan,
			name: funnelData.step1.plan,
			price: amountTotal,
			currency: branch === 'co' ? 'cop' : 'usd',
			value: amountTotal,
		});

		setFunnelData(funnelData);
		setFunnel(funnelData);
		history.push('/info');
	};

	const checkVehicleYearBox = () => {
		if (vehicleCount >= 1) {
			return (
				<div className="cobertura-select-checkbox-container">
					<div className="title">
						{vehicleCount === 1 ? `El auto es del ${year} o más nuevo` : `Los autos son del ${year} o más nuevos`}
					</div>
					<div className="checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={checkVehicle}
									onChange={handleAutoYearCheckbox}
									icon={<CheckBoxOutlineBlankOutlinedIcon className="fill-white" />}
									checkedIcon={<DoneIcon className="border-fill-white" />}
								/>
							}
							label=""
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	const handleAutoYearCheckbox = (event: any) => {
		setCheckedVehicle(event.target.checked);
	};

	const checkMotoYearBox = () => {
		if (motoCount >= 1) {
			return (
				<div className="cobertura-select-checkbox-container">
					<div className="title">
						{motoCount === 1
							? `La ${getVehicleFormCountry(branch)} es del ${year} o más nueva`
							: `Las ${getVehicleFormCountry(branch, true)} son del ${year} o más nuevas`}
					</div>
					<div className="checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={checkedMoto}
									onChange={handleMotoYearCheckbox}
									icon={<CheckBoxOutlineBlankOutlinedIcon className="fill-white" />}
									checkedIcon={<DoneIcon className="border-fill-white" />}
								/>
							}
							label=""
						/>
					</div>
				</div>
			);
		} else {
			return null;
		}
	};

	const handleMotoYearCheckbox = (event: any) => {
		setCheckedMoto(event.target.checked);
	};

	const getTotal = () => {
		const p = funnel.step1.pago === 'anual' ? funnel.total.anualPrice : funnel.total.mensualPrice;
		let finalPrice = 0;
		if (p && p > 0) {
			if (funnel.step3.discountAmount && funnel.step3.discountAmount > 0) {
				finalPrice = p - funnel.step3.discountAmount;
			} else {
				finalPrice = p;
			}
		}
		return finalPrice?.toFixed(2);
	};

	useEffect(() => {
		const country = branchQP ? branchQP : getBranch();
		setBranchStatus(country);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBranch = () => {
		return (
			{
				'beta.cra.pr': 'pr',
				'connect.cr': 'cr',
				'connect.com.pa': 'pty',
				'connect.com.co': 'co',
			}[window.location.hostname] ||
			params.get('country') ||
			defaultBranch
		);
	};

	return (
		<div className="App">
			<Helmet>
				<title>Cobertura - Connect Assistance</title>
				<meta name="description" content="Escoge el tipo de plan que necesitas" />
			</Helmet>
			<div className="App-header">
				<FunnelFooterMobile className="mobile" section="steps" active="cobertura" linkTo="pago" showChevron={false} />
				<FunnelHeader progress="1" />
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner">
					<div className="step-header">
						<h3 className="cobertura-h3">Escoge el tipo de plan que necesitas</h3>
						<p className="cobertura-p text-left">
							Recibe descuentos pagando anualmente {branch !== 'co' && 'y añadiendo 2 o más propiedades'}
						</p>
					</div>
					<div className="funnel__cols">
						<div className="funnel__col funnel__col-1 no-divider cobertura-direction-column">
							<div className="cobertura-select-content">
								<div className="cobertura-select-items">
									<div className="cobertura-select-item-type-content">
										<img className="cobertura-auto" src={CarIcon} alt="" />
									</div>
									<div className="cobertura-select-item-type-label">
										<p>Autos</p>
									</div>
									<div className="cobertura-select-item-qty-content">
										<Link
											to="#"
											onClick={async () => await handleVehicleChange('minus')}
											className="qty-icon minus-icon"
										>
											<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<title>-</title>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
													fill="#F15B2B"
												/>
											</svg>
										</Link>
										<div className="cobertura-qty-label">{vehicleCount}</div>
										<Link to="#" onClick={() => handleVehicleChange('plus')} className="qty-icon plus-icon">
											<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect x="4.375" width="1.25" height="10" rx="0.625" fill="#F15B2B" />
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
													fill="#F15B2B"
												/>
											</svg>
										</Link>
									</div>
								</div>

								{checkVehicleYearBox()}

								{branch === 'pr' && (
									<div className="cobertura-select-items">
										<div className="cobertura-select-item-type-content">
											<img className="cobertura-moto" src={MotoIcon} alt="" />
										</div>
										<div className="cobertura-select-item-type-label">
											<p>{getVehicleFormCountry(branch, true, true)}</p>
										</div>
										<div className="cobertura-select-item-qty-content">
											<Link to="#" onClick={() => handleMotoChange('minus')} className="qty-icon minus-icon">
												<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<title>-</title>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
														fill="#F15B2B"
													/>
												</svg>
											</Link>
											<div className="cobertura-qty-label">{motoCount}</div>
											<Link to="#" onClick={() => handleMotoChange('plus')} className="qty-icon plus-icon">
												<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<title>+</title>
													<rect x="4.375" width="1.25" height="10" rx="0.625" fill="#F15B2B" />
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
														fill="#F15B2B"
													/>
												</svg>
											</Link>
										</div>
									</div>
								)}
								{branch === 'pr' ? checkMotoYearBox() : ''}

								{branch === 'pr' && (
									<div className="cobertura-select-items">
										<div className="cobertura-select-item-type-content">
											<img className="cobertura-home" src={HomeIcon} alt="" />
										</div>
										<div className="cobertura-select-item-type-label">
											<p>Hogar</p>
										</div>
										<div className="cobertura-select-item-qty-content">
											<Link to="#" onClick={() => handleHomeChange('minus')} className="qty-icon minus-icon">
												<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<title>-</title>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
														fill="#F15B2B"
													/>
												</svg>
											</Link>
											<div className="cobertura-qty-label">{homeCount}</div>
											<Link to="#" onClick={() => handleHomeChange('plus')} className="qty-icon plus-icon">
												<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
													<title>+</title>
													<rect x="4.375" width="1.25" height="10" rx="0.625" fill="#F15B2B" />
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
														fill="#F15B2B"
													/>
												</svg>
											</Link>
										</div>
									</div>
								)}
							</div>
							<div className="cobertura-payment-content">
								<RadioButton
									handleClick={() => handlePago('mensual')}
									className={`${pago === 'mensual' ? 'active' : ''}`}
									price={`${Pricing.formatPrice(total.mensualPrice, branch, 2)}`}
									checkedStatus={pago === 'mensual' ? true : false}
									label={'Pago Mensual'}
								></RadioButton>
								<RadioButton
									handleClick={() => handlePago('anual')}
									className={`${pago === 'anual' ? 'active' : ''}`}
									price={`${Pricing.formatPrice(total.anualPrice, branch, 2)}`}
									pricePerc={!funnelData.step3.validCoupon ? total.anualPerc : undefined}
									checkedStatus={pago === 'anual' ? true : false}
									label={'Pago Anual'}
								>
									<div className="active-indicator"></div>
								</RadioButton>
							</div>

							<OrangeButton handleClick={handleContinuarBtn} className="full-width funnel-advance mobile">
								Continuar
							</OrangeButton>
						</div>
						{/* TODO #EB @Connect-Devs Display this box only after qty > 5 */}
						<div className="funnel__col funnel__col-2 ctr cobertura-necesitas-content">
							<div className="cobertura-necesitas-div">
								<p className="cobertura-p">
									¿Necesitas suscribir 10 <br></br> o más vehículos?
								</p>
								<a className="cobertura-link" href={`${getBranchUrl(branch)}/contacto`} rel="noopener noreferrer">
									Contáctanos
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<FunnelFooterDesktop
				handleClick={handleContinuarBtn}
				section="steps"
				active="cobertura"
				linkTo="info"
				showChevron={false}
			/>
		</div>
	);
};

export default FunnelCobertura;
