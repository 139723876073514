import React, { useEffect, useContext } from 'react';
import OrangeTitle from 'components/orange-title/orange-title';
import TrabajaNosotros from 'components/home/trabaja-nosotros/trabaja-nosotros';

import ExpImg1 from 'images/sobre/Un_llamado_a_revolucionar_el_sector.jpg';
import ExpImg2 from 'images/sobre/Ocho_personas_que_parecían_mil.jpg';
import ExpImg3 from 'images/sobre/Brillando_en_tres_mercados.jpg';
import starSvg from 'images/svg/star--illustrated.svg';
import { AppContext } from 'helpers/Context';
import { Helmet } from 'react-helmet';

import './sobre.scss';

export default function Home() {
	const { identify, setIdentifyStatus } = useContext(AppContext);

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="App">
			<Helmet>
				<title>Sobre Nosotros - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="about__banner">
				<h2 className="ct-container">Seguro y en buenas manos, donde sea que estés.</h2>
			</div>

			<div className="sobre-nosotros ct-container ctr-mobile">
				<OrangeTitle className="ctr">Sobre Nosotros</OrangeTitle>
				<div className="item">
					<div className="img">
						<img src={ExpImg1} alt="" />
					</div>
					<div className="copy">
						<h2>Un llamado a revolucionar el sector</h2>
						<p>
							Una llamada de asistencia que obtuvo como respuesta un “podemos enviarle una grúa… mañana” fue el
							detonante del nacimiento de Connect. Sorprendidos ante la poca innovación del sector en más de diez años,
							fundamos Connect en Puerto Rico en 2011.
						</p>
					</div>
				</div>
				<div className="item reverse">
					<div className="img">
						<img src={ExpImg2} alt="" />
					</div>
					<div className="copy">
						<h2>Ocho personas que parecían mil</h2>
						<p>
							Comenzamos en Puerto Rico con un equipo de ocho personas, solo una fracción de lo que eran nuestros
							competidores. Por ello nos propusimos utilizar tecnología e innovación para dar un servicio extraordinario
							que convirtiera a nuestros propios clientes en embajadores de la marca.
						</p>
					</div>
				</div>
				<div className="item">
					<div className="img">
						<img src={ExpImg3} alt="" />
					</div>
					<div className="copy">
						<h2>Brillando en cuatro mercados</h2>
						<p>
							A diez años de nuestra fundación, contamos con más de 850 mil clientes entre Puerto Rico, Panamá, Costa
							Rica, y Colombia y hemos añadido asistencia en hogar a nuestros servicios y nos preparamos para seguir
							entrando nuevos mercados.
						</p>
					</div>
				</div>

				<div id="mission">
					<OrangeTitle>Lo que nos mueve</OrangeTitle>
					<h2>
						Construimos el futuro que queremos para nosotros y para nuestra comunidad, resolviendo problemas ordinarios
						con soluciones extraordinarias.
					</h2>
				</div>
			</div>

			<div id="reviews">
				<div className="ct-container">
					<div className="trabaja__cols_reviews">
						<OrangeTitle>Google Reviews</OrangeTitle>
						<h2>Un cliente satisfecho es nuestro mejor reconocimiento</h2>
						<div className="reviews__rating">
							<span className="upper">4.8</span>
							<span className="lower">/5</span>
						</div>
						<div className="reviews__stars">
							<img src={starSvg} alt="cinco estrellas" />
						</div>
						<div className="reviews__count">
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://www.google.com/search?q=connect+assistance&rlz=1C5CHFA_enPR857PR857&oq=connect+assistance&aqs=chrome..69i57j69i59j69i60l2j69i61j69i65l3.525j0j1&sourceid=chrome&ie=UTF-8"
							>
								501 Reseñas
							</a>
						</div>
						<div>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href="https://www.google.com/search?q=connect+assistance&rlz=1C5CHFA_enPR857PR857&oq=connect+assistance&aqs=chrome..69i57j69i59j69i60l2j69i61j69i65l3.525j0j1&sourceid=chrome&ie=UTF-8"
								className="button"
							>
								Ver Más Reseñas
							</a>
						</div>
					</div>
				</div>
			</div>

			<TrabajaNosotros />
		</div>
	);
}
