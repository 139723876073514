import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import './header-desktop.scss';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../../helpers/Context';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import { getVehicleFormCountry, getBranchUrl } from '../../../helpers/Convertions';

export default function HeaderDesktop() {
	const { t } = useTranslation();
	const { branch } = useContext(AppContext);
	const linkTo = getFunnelUrl(branch);
	const branchUrl = getBranchUrl(window.location.hostname);

	return (
		<header className="desktop">
			<Link to={{ pathname: branchUrl }} className="logo header__col left">
				<img src="../images/svg/Connect-Assistance--logo--primary.svg" alt="Connect Assistance" />
			</Link>

			<nav className="header__col center">
				<ul className="top">
					<li className="top dd">
						<Link to="/">Servicios</Link>
						<ul className="sub">
							{branch === 'pr' && (
								<li>
									<Link to="/servicios">Servicios</Link>
								</li>
							)}

							<li>
								<Link to={['pr', 'cr', 'co'].includes(branch) ? '/servicios-shared/auto' : linkTo}>
									Asistencia para auto
								</Link>
							</li>
							{branch === 'pr' && (
								<li>
									<Link to={['pr'].includes(branch) ? '/servicios-shared/motora' : linkTo}>
										Asistencia para {getVehicleFormCountry(branch)}
									</Link>
								</li>
							)}
							{branch === 'pr' && (
								<li>
									<Link to={'/servicios-shared/hogar'}>Asistencia para hogar</Link>
								</li>
							)}
						</ul>
					</li>
					<li className="top dd">
						<Link to="/">Sobre Nosotros</Link>
						<ul className="sub">
							<li>
								<Link to="/sobre-nosotros">Sobre Nosotros</Link>
							</li>
							{['co', 'pr'].includes(branch) && (
								<li>
									<Link to="/politicas">Políticas</Link>
								</li>
							)}
							{['pr'].includes(branch) && (
								<li>
									<Link to="/faq">FAQ</Link>
								</li>
							)}
							<li>
								<Link to="/contacto">Contacto</Link>
							</li>
						</ul>
					</li>
					<li className="top">
						<a rel="noopener noreferrer" target="_blank" href="https://jobs.lever.co/connectassistance">
							Empleos
						</a>
					</li>
				</ul>
			</nav>

			<div className="header__col right">
				<a target="_top" rel="noopener noreferrer" href={linkTo} className="button">
					Suscríbete
				</a>
				<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')} className="icon-btn chat">
					Chat
				</a>
				<a href={t('phoneLink')} className="icon-btn phone">
					Chat
				</a>
			</div>
		</header>
	);
}
