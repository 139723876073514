import moment from 'moment';

import { FunnelData } from '../interfaces/FunnelInterface';
import api from './Api';
import { getAccountIdByBranch } from 'helpers/Convertions';

const processPayment = async (funnelData: FunnelData, paymentData, tokenCaptcha: string) => {
	if (!paymentData.token) return paymentData.token;
	const countryPlanIdentifier = funnelData.branch === 'co' ? '-co' : '';

	const plan = `${funnelData.step1.cobertura.auto}-${funnelData.step1.cobertura.motora}-${
		funnelData.step1.cobertura.hogar
	}-${funnelData.step1.pago === 'anual' ? 'A' : 'M'}${countryPlanIdentifier}`;
	const pago = funnelData.step1.pago === 'anual' ? 'A' : 'M';
	funnelData.step1.plan = plan;
	//Este objeto se tiene que manetener asi aunque no se usen muchos campos. Es para no hacer cambios en el API existente. Ya que vamos hacer un API nuevo.
	const accountId = await getAccountIdByBranch(funnelData.branch);
	console.log('accountId', accountId);
	const subscriptionData = {
		version: 'v2',
		// campaign: this.cv.cart.inputs.campaign,
		primer_nombre: funnelData.step2.nombre,
		apellido: funnelData.step2.apellido,
		email: funnelData.step2.email,
		telefono: funnelData.step2.telefono,
		nombre_tarjeta: funnelData.step3.nombreTarjeta,
		monthlyOrAnnual: pago,
		plan: plan,
		branch: funnelData.branch,
		// accountId: this.cv.cart.inputs.accountId,
		token: paymentData.token.id,
		coupon: funnelData.step3.coupon,
		discount: funnelData.step3.discount,
		subscription_data: {
			data: {
				accountId: accountId,
				agentName: funnelData.preSale ? funnelData.subscriptionData.agentName : 'CRA Website',
				amount: '',
				anualAuto: funnelData.total.autoAnualPrice,
				anualHogar: funnelData.total.homeAnualPrice,
				anualMotora: funnelData.total.motoAnualPrice,
				authorizePromosInSale: true,
				autoColor1: funnelData.subscriptionData.autoColor1,
				autoColor2: funnelData.subscriptionData.autoColor2,
				autoColor3: funnelData.subscriptionData.autoColor3,
				autoColor4: funnelData.subscriptionData.autoColor4,
				autoColor5: funnelData.subscriptionData.autoColor5,
				autoMake1: funnelData.subscriptionData.autoMake1,
				autoMake2: funnelData.subscriptionData.autoMake2,
				autoMake3: funnelData.subscriptionData.autoMake3,
				autoMake4: funnelData.subscriptionData.autoMake4,
				autoMake5: funnelData.subscriptionData.autoMake5,
				autoModel1: funnelData.subscriptionData.autoModel1,
				autoModel2: funnelData.subscriptionData.autoModel2,
				autoModel3: funnelData.subscriptionData.autoModel3,
				autoModel4: funnelData.subscriptionData.autoModel4,
				autoModel5: funnelData.subscriptionData.autoModel5,
				autoPlate1: funnelData.subscriptionData.autoPlate1,
				autoPlate2: funnelData.subscriptionData.autoPlate2,
				autoPlate3: funnelData.subscriptionData.autoPlate3,
				autoPlate4: funnelData.subscriptionData.autoPlate4,
				autoPlate5: funnelData.subscriptionData.autoPlate5,
				autoSelected: funnelData.step1.cobertura.auto,
				autoStatus1: '',
				autoStatus2: '',
				autoStatus3: '',
				autoStatus4: '',
				autoStatus5: '',
				autoYear1: funnelData.subscriptionData.autoYear1,
				autoYear2: funnelData.subscriptionData.autoYear2,
				autoYear3: funnelData.subscriptionData.autoYear3,
				autoYear4: funnelData.subscriptionData.autoYear4,
				autoYear5: funnelData.subscriptionData.autoYear5,
				campaign: process.env.NODE_ENV === 'production' ? 'Connect Assistance' : 'Dev Test Account',
				cardCCV: '',
				cardIdStripe: '',
				cardMonth: '',
				cardNum: '',
				cardYear: '',
				couponId: funnelData.step3.couponId,
				customerIdStripe: '',
				email: funnelData.step2.email,
				firstName: funnelData.step2.nombre,
				hasDiscount: funnelData.step3.hasDiscount,
				hogarSelected: funnelData.step1.cobertura.hogar,
				homeAddress11: funnelData.subscriptionData.homeAddress11,
				homeAddress12: funnelData.subscriptionData.homeAddress12,
				homeAddress13: funnelData.subscriptionData.homeAddress13,
				homeAddress14: funnelData.subscriptionData.homeAddress14,
				homeAddress15: funnelData.subscriptionData.homeAddress15,
				homeAddress21: funnelData.subscriptionData.homeAddress21,
				homeAddress22: funnelData.subscriptionData.homeAddress22,
				homeAddress23: funnelData.subscriptionData.homeAddress23,
				homeAddress24: funnelData.subscriptionData.homeAddress24,
				homeAddress25: funnelData.subscriptionData.homeAddress25,
				homeCity1: funnelData.subscriptionData.homeCity1,
				homeCity2: funnelData.subscriptionData.homeCity2,
				homeCity3: funnelData.subscriptionData.homeCity3,
				homeCity4: funnelData.subscriptionData.homeCity4,
				homeCity5: funnelData.subscriptionData.homeCity5,
				homeStatus1: 'Waiting Period',
				homeStatus2: '',
				homeStatus3: '',
				homeStatus4: '',
				homeStatus5: '',
				homeZipCode1: funnelData.subscriptionData.homeZipCode1,
				homeZipCode2: funnelData.subscriptionData.homeZipCode2,
				homeZipCode3: funnelData.subscriptionData.homeZipCode3,
				homeZipCode4: funnelData.subscriptionData.homeZipCode4,
				homeZipCode5: funnelData.subscriptionData.homeZipCode5,
				lastName: funnelData.step2.apellido,
				logMessage: 'Created a new subscription.',
				mensualAuto: funnelData.total.autoMensualPrice,
				mensualHogar: funnelData.total.homeMensualPrice,
				mensualMotora: funnelData.total.motoMensualPrice,
				mongoSubId: '',
				monthlyOrAnnual: pago,
				motoraColor1: funnelData.subscriptionData.motoraColor1,
				motoraColor2: funnelData.subscriptionData.motoraColor2,
				motoraColor3: funnelData.subscriptionData.motoraColor3,
				motoraColor4: funnelData.subscriptionData.motoraColor4,
				motoraColor5: funnelData.subscriptionData.motoraColor5,
				motoraMake1: funnelData.subscriptionData.motoraMake1,
				motoraMake2: funnelData.subscriptionData.motoraMake2,
				motoraMake3: funnelData.subscriptionData.motoraMake3,
				motoraMake4: funnelData.subscriptionData.motoraMake4,
				motoraMake5: funnelData.subscriptionData.motoraMake5,
				motoraModel1: funnelData.subscriptionData.motoraModel1,
				motoraModel2: funnelData.subscriptionData.motoraModel2,
				motoraModel3: funnelData.subscriptionData.motoraModel3,
				motoraModel4: funnelData.subscriptionData.motoraModel4,
				motoraModel5: funnelData.subscriptionData.motoraModel5,
				motoraPlate1: funnelData.subscriptionData.motoraPlate1,
				motoraPlate2: funnelData.subscriptionData.motoraPlate2,
				motoraPlate3: funnelData.subscriptionData.motoraPlate3,
				motoraPlate4: funnelData.subscriptionData.motoraPlate4,
				motoraPlate5: funnelData.subscriptionData.motoraPlate5,
				motoraSelected: funnelData.step1.cobertura.motora,
				motoraYear1: funnelData.subscriptionData.motoraYear1,
				motoraYear2: funnelData.subscriptionData.motoraYear2,
				motoraYear3: funnelData.subscriptionData.motoraYear3,
				motoraYear4: funnelData.subscriptionData.motoraYear4,
				motoraYear5: funnelData.subscriptionData.motoraYear5,
				motorcycleStatus1: '',
				motorcycleStatus2: '',
				motorcycleStatus3: '',
				motorcycleStatus4: '',
				motorcycleStatus5: '',
				name_payment: '',
				paymentDay: moment().format('D'),
				phone: funnelData.step2.telefono,
				plan: plan,
				promotionCodeId: funnelData.step3.promotionCodeId,
				selectedpago: pago,
				status: '',
				subscriptionIdStripe: '',
				coupon: funnelData.step3.coupon,
				versionPrice: 1,
				isFacebookLanding: funnelData.step3.isFacebookLanding,
				identification: funnelData.step2.numeroCedula,
				identificationType: funnelData.step2.tipoCedula,
				state: funnelData.step2.estado,

				provincia: funnelData.step2.estado,
				canton: funnelData.step2.canton,
				city: funnelData.step2.distrito,
				zipCode: funnelData.step2.codigoPostal,
				address1: funnelData.step2.direccion,
				utm_source: funnelData.utm.utm_source,
				utm_medium: funnelData.utm.utm_medium,
				utm_campaign: funnelData.utm.utm_campaign,
				utm_id: funnelData.utm.utm_id,
				platform: 'CRA Website',
			},
			mensualAuto: funnelData.total.autoMensualPrice,
			mensualMotora: funnelData.total.motoMensualPrice,
			mensualHogar: funnelData.total.homeMensualPrice,
			anualAuto: funnelData.total.autoAnualPrice,
			anualMotora: funnelData.total.motoAnualPrice,
			anualHogar: funnelData.total.homeAnualPrice,
			authorizePromo: false,
			monthlyOrAnnual: pago,
		},
	};
	const headers = {
		authorizationtoken: tokenCaptcha,
	};

	const customerWithPlan = await api.post('/sales/cCustomerWithPlanPwr', subscriptionData, { headers });
	return customerWithPlan;
};

export { processPayment };
