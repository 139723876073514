import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Container from 'components/container/container';
import OrangeButton from 'components/inputs/orange-button/orange-button';
import TextInput from 'components/inputs/textfield/textfield';
import { AppContext } from 'helpers/Context';
import Toaster from 'helpers/Toaster';
import { funnelForm } from 'services/ContactService';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import './pty-funnel.scss';

interface IFormInputs {
	nombre: string;
	email: string;
	branch: string;
}

const PtyFunel: React.FC = () => {
	const history = useHistory();
	const { branch } = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(false);
	const initialValues: IFormInputs = {
		nombre: '',
		email: '',
		branch: branch,
	};
	const [isSend, setSend] = useState(false);
	const { identify, setIdentifyStatus } = useContext(AppContext);

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {};
		// eslint-disable-next-line
	}, [isLoading]);

	const validate = (values: any) => {
		const errors: any = {};
		if (!values.nombre) {
			errors.nombre = 'Campo Requerido';
		}

		if (!values.email) {
			errors.email = 'Campo Requerido';
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email address';
		}

		return errors;
	};

	const formik = useFormik({
		initialValues,
		validate,
		onSubmit: async (values) => {
			setIsLoading(true);
			setSend(true);
			const data = {
				nombre: values.nombre,
				email: values.email,
				branch: branch,
			};

			const ptyFunnelResponse = await funnelForm(data);
			if (!ptyFunnelResponse) {
				Toaster.error('Se produjo un error.');
				setIsLoading(false);
				return;
			}

			setIsLoading(false);

			history.push('/');
		},
	});
	return (
		<div className="App">
			<Helmet>
				<title>¡Déjanos tus datos y nos comunicaremos contigo pronto! - Connect Assistance - Panamá</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header">
				<Container>
					<div className="pty-funnel-container">
						<div className="ctr">
							<h4 className="margin-top-20">
								¡Pronto estaremos disponible en Panamá con nuestros planes de Asistencia Vial y Remolque!
							</h4>
							<div className="margin-top-20">Déjanos tus datos y te notificaremos pronto.</div>
							<form className={`contacto-form-container`} onSubmit={formik.handleSubmit}>
								<TextInput
									name="nombre"
									className="contacto-form-container-input"
									id="outlined-basic"
									label="Nombre Completo"
									onChange={formik.handleChange}
									value={formik.values.nombre}
									errorinput={formik.errors.nombre ? 1 : 0}
									errorlabel={formik.errors.nombre}
								/>

								<TextInput
									type="email"
									name="email"
									className="contacto-form-container-input"
									id="outlined-basic"
									label="Correo electrónico"
									onChange={formik.handleChange}
									value={formik.values.email}
									errorinput={formik.errors.email ? 1 : 0}
									errorlabel={formik.errors.email}
								/>

								<OrangeButton
									isDisabled={isSend}
									className={classnames('contacto-orange-btn margin-top-20 full-width', { 'button-disabled': isSend })}
								>
									Enviar
								</OrangeButton>
								<p className="infoEnviada">{isSend && '¡Gracias, su información ha sido enviada!'}</p>
							</form>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default PtyFunel;
