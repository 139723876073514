import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ResumenInfo = (props: any) => {
	const { t } = useTranslation();
	const [resumenArray]: any = useState(t(`resumen.${props.category}.plan`, { returnObjects: true }));

	return (
		<div>
			<div className="funnel-resumen-bottom-div">
				<div className="mas-info uppercase  light-gray">Detalles de tu plan</div>
				<div className="funnel-resumen-h4 margin-top-12">
					{t(`resumen.${props.category}.resumenTitulo`)} ({props.price})
				</div>
				<ul>
					{resumenArray.map(function (d: any, index: number) {
						return <li key={index}>{d.title}</li>;
					})}
				</ul>

				<Link onClick={() => props.modalClick(props.category)} to="#" className="funnel-resume-link">
					Ver más detalles
				</Link>
			</div>
		</div>
	);
};

export default ResumenInfo;
