import React from 'react';

import './experiencia-connect.scss';
import Container from '../container/container';

interface Props {
	title: string;
	subTitle: string;
	reverse: boolean;
	img: string;
}

const ExperienciaConnect: React.FC<Props> = ({ title, subTitle, reverse, img }) => {
	return (
		<Container>
			<div className={`exp-content ${reverse ? 'exp-reverse' : ''}`}>
				<div>
					<h2>{title}</h2>
					<p>{subTitle}</p>
				</div>
				<div>
					<img className="exp-circle" src={img} alt="" />
				</div>
			</div>
		</Container>
	);
};

export default ExperienciaConnect;
