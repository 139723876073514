import React, { useContext, useEffect, useState } from 'react';
import './confirmacion.scss';
import OrangeButton from 'components/inputs/orange-button/orange-button';

import FunnelHeader from 'components/funnel/header/header';
import { Link, useHistory } from 'react-router-dom';
import FunnelFooterDesktop from 'components/funnel/footer/desktop/funnel-footer-desktop';
import FunnelFooterMobile from 'components/funnel/footer/mobile/funnel-footer-mobile';
import CongratsImg from 'images/funnel/confirmacion1.svg';
import { AppContext } from 'helpers/Context';
import { GlobalContext } from 'context/GlobalState';
import { buildPerfilArray } from 'helpers/Peril';
import { useParams } from 'react-router';
import { getSubscription } from 'services/SubscriptionService';

export default function FunnelConfirmacion() {
	const { setShowStatus, branch} = useContext(AppContext);
	const { funnel, setFunnel } = useContext(GlobalContext);
	const history = useHistory();
	const [notification, setTypeNotification] = useState<any | null>(null);
	let sub = useParams();

	const handleContinuarBtn = () => {
		history.push('/perfil');
	};

	const getSub = async (id: string) => {
		const { data } = await getSubscription(id);
		return data.data;
	};

	useEffect(() => {
		getSub(sub['id']).then((data) => {
			funnel.subscriptionData.mongoId = sub['id'];
			funnel.step1.cobertura.auto = data['autoSelected'];
			funnel.step1.cobertura.hogar = data['hogarSelected'];
			funnel.step1.cobertura.motora = data['motoraSelected'];
			funnel.perfilData = buildPerfilArray({
				auto: data['autoSelected'],
				hogar: data['hogarSelected'],
				motora: data['motoraSelected'],
			}, branch);
			//AUTO
			funnel.subscriptionData.autoSelected = data['autoSelected'];
			funnel.subscriptionData.autoColor1 = data['autoColor1'];
			funnel.subscriptionData.autoColor2 = data['autoColor2'];
			funnel.subscriptionData.autoColor3 = data['autoColor3'];
			funnel.subscriptionData.autoColor4 = data['autoColor4'];
			funnel.subscriptionData.autoColor5 = data['autoColor5'];
			funnel.subscriptionData.autoMake1 = data['autoMake1'];
			funnel.subscriptionData.autoMake2 = data['autoMake2'];
			funnel.subscriptionData.autoMake3 = data['autoMake3'];
			funnel.subscriptionData.autoMake4 = data['autoMake4'];
			funnel.subscriptionData.autoMake5 = data['autoMake5'];
			funnel.subscriptionData.autoModel1 = data['autoModel1'];
			funnel.subscriptionData.autoModel2 = data['autoModel2'];
			funnel.subscriptionData.autoModel3 = data['autoModel3'];
			funnel.subscriptionData.autoModel4 = data['autoModel4'];
			funnel.subscriptionData.autoModel5 = data['autoModel5'];
			funnel.subscriptionData.autoPlate1 = data['autoPlate1'];
			funnel.subscriptionData.autoPlate2 = data['autoPlate2'];
			funnel.subscriptionData.autoPlate3 = data['autoPlate3'];
			funnel.subscriptionData.autoPlate4 = data['autoPlate4'];
			funnel.subscriptionData.autoPlate5 = data['autoPlate5'];
			funnel.subscriptionData.autoYear1 = data['autoYear1'];
			funnel.subscriptionData.autoYear2 = data['autoYear2'];
			funnel.subscriptionData.autoYear3 = data['autoYear3'];
			funnel.subscriptionData.autoYear4 = data['autoYear4'];
			funnel.subscriptionData.autoYear5 = data['autoYear5'];
			//MOTORA
			funnel.subscriptionData.motoraSelected = data['motoraSelected'];
			funnel.subscriptionData.motoraColor1 = data['motoraColor1'];
			funnel.subscriptionData.motoraColor2 = data['motoraColor2'];
			funnel.subscriptionData.motoraColor3 = data['motoraColor3'];
			funnel.subscriptionData.motoraColor4 = data['motoraColor4'];
			funnel.subscriptionData.motoraColor5 = data['motoraColor5'];
			funnel.subscriptionData.motoraMake1 = data['motoraMake1'];
			funnel.subscriptionData.motoraMake2 = data['motoraMake2'];
			funnel.subscriptionData.motoraMake3 = data['motoraMake3'];
			funnel.subscriptionData.motoraMake4 = data['motoraMake4'];
			funnel.subscriptionData.motoraMake5 = data['motoraMake5'];
			funnel.subscriptionData.motoraModel1 = data['motoraModel1'];
			funnel.subscriptionData.motoraModel2 = data['motoraModel2'];
			funnel.subscriptionData.motoraModel3 = data['motoraModel3'];
			funnel.subscriptionData.motoraModel4 = data['motoraModel4'];
			funnel.subscriptionData.motoraModel5 = data['motoraModel5'];
			funnel.subscriptionData.motoraYear1 = data['motoraYear1'];
			funnel.subscriptionData.motoraYear2 = data['motoraYear2'];
			funnel.subscriptionData.motoraYear3 = data['motoraYear3'];
			funnel.subscriptionData.motoraYear4 = data['motoraYear4'];
			funnel.subscriptionData.motoraYear5 = data['motoraYear5'];
			funnel.subscriptionData.motoraPlate1 = data['motoraPlate1'];
			funnel.subscriptionData.motoraPlate2 = data['motoraPlate2'];
			funnel.subscriptionData.motoraPlate3 = data['motoraPlate3'];
			funnel.subscriptionData.motoraPlate4 = data['motoraPlate4'];
			funnel.subscriptionData.motoraPlate5 = data['motoraPlate5'];
			//HOGAR
			funnel.subscriptionData.hogarSelected = data['hogarSelected'];
			funnel.subscriptionData.homeAddress11 = data['homeAddress11'];
			funnel.subscriptionData.homeAddress12 = data['homeAddress12'];
			funnel.subscriptionData.homeAddress13 = data['homeAddress13'];
			funnel.subscriptionData.homeAddress14 = data['homeAddress14'];
			funnel.subscriptionData.homeAddress15 = data['homeAddress15'];
			funnel.subscriptionData.homeBanos1 = data['homeBanos1'];
			funnel.subscriptionData.homeBanos2 = data['homeBanos2'];
			funnel.subscriptionData.homeBanos3 = data['homeBanos3'];
			funnel.subscriptionData.homeBanos4 = data['homeBanos4'];
			funnel.subscriptionData.homeBanos5 = data['homeBanos5'];
			funnel.subscriptionData.homeCity1 = data['homeCity1'];
			funnel.subscriptionData.homeCity2 = data['homeCity2'];
			funnel.subscriptionData.homeCity3 = data['homeCity3'];
			funnel.subscriptionData.homeCity4 = data['homeCity4'];
			funnel.subscriptionData.homeCity5 = data['homeCity5'];
			funnel.subscriptionData.homeCountry1 = data['homeCountry1'];
			funnel.subscriptionData.homeCountry2 = data['homeCountry2'];
			funnel.subscriptionData.homeCountry3 = data['homeCountry3'];
			funnel.subscriptionData.homeCountry4 = data['homeCountry4'];
			funnel.subscriptionData.homeCountry5 = data['homeCountry5'];
			funnel.subscriptionData.homeRooms1 = data['homeRooms1'];
			funnel.subscriptionData.homeRooms2 = data['homeRooms2'];
			funnel.subscriptionData.homeRooms3 = data['homeRooms3'];
			funnel.subscriptionData.homeRooms4 = data['homeRooms4'];
			funnel.subscriptionData.homeRooms5 = data['homeRooms5'];
			funnel.subscriptionData.homeType1 = data['homeType1'];
			funnel.subscriptionData.homeType2 = data['homeType2'];
			funnel.subscriptionData.homeType3 = data['homeType3'];
			funnel.subscriptionData.homeType4 = data['homeType4'];
			funnel.subscriptionData.homeType5 = data['homeType5'];
			funnel.subscriptionData.homeZipCode1 = data['homeZipCode1'];
			funnel.subscriptionData.homeZipCode2 = data['homeZipCode2'];
			funnel.subscriptionData.homeZipCode3 = data['homeZipCode3'];
			funnel.subscriptionData.homeZipCode4 = data['homeZipCode4'];
			funnel.subscriptionData.homeZipCode5 = data['homeZipCode5'];
			setTypeNotification(sub['notification']);
		});

		// For test only
		// funnel.perfilData = buildPerfilArray({ auto: 0, hogar: 1, motora: 0 });

		setFunnel(funnel);
		setShowStatus(false);
		return () => {
			setShowStatus(true);
		};
		// eslint-disable-next-line
	}, [setShowStatus]);
	return (
		<div className="App">
			<div className="App-header">
				<FunnelFooterMobile
					className="mobile"
					section="CONFIRMACIÓN"
					btnLabel="completar perfil"
					linkTo="perfil-vehiculo"
					showChevron={false}
				/>
				<FunnelHeader progress="4" />
			</div>
			<div className="funnel__main">
				<div className="funnel__main__inner two-col full-width">
					<div className="funnel__main__inner__right illustration">
						<img className="confirmation-direction-column-img" src={CongratsImg} alt="" />
					</div>

					<div className="funnel__main__inner__left text text-center-mobile v-ctr">
						<div className="inner">
							<h3>Felicidades y bienvenido a Connect Assistance</h3>
							<p>
								{notification === '0'
									? 'Te acabamos de enviar un correo electrónico con la confirmación de tu plan y	el recibo.'
									: ''}
							</p>
							<br />
							<p>Para activar tu plan, por favor completa los detalles de tu suscripción.</p>
						</div>
					</div>
				</div>
			</div>

			<div className="confirmation-content">
				<div className="confirmation-direction-column">
					<div className="confirmation-left-content"></div>
					<Link to={'/perfil/' + sub['id'] + '/0'} className="full-width mobile">
						<OrangeButton className="full-width mobile">Completar perfil</OrangeButton>
					</Link>
				</div>

				<div className="confirmation-direction-column"></div>
			</div>
			<FunnelFooterDesktop
				className="desktop"
				section="CONFIRMACIÓN"
				btnLabel="Continuar"
				handleClick={handleContinuarBtn}
			/>
		</div>
	);
}
