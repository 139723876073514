export const getLabels = (branch: string) => {
	return {
		co: {
			province: 'Departamento',
			canton: 'Municipio',
		},
		cr: {
			province: 'Provincia',
			canton: 'Cantón',
		},
		pty: {
			province: 'Provincia',
			canton: 'Cantón',
		},
	}[branch];
};
