import React, { useContext, useEffect } from 'react';
import SlideContent from 'components/primary-content/primary-content';
import ExperienciaConnect from 'components/experiencia-connect/experiencia-connect';
import OrangeTitle from 'components/orange-title/orange-title';
import Servicios from 'components/home/servicios/servicios';
import NuestrosClientes from 'components/home/nuestros-clientes/nuestros-clientes';
import TrabajaNosotros from 'components/home/trabaja-nosotros/trabaja-nosotros';
import SliderClients from 'components/home/slider-clients/slider-clients';

import ExpImg1 from 'images/svg/Tecnologia-Innovadora.svg';
import ExpImg2 from 'images/svg/Cubrimos-Cada-Rincon.svg';
import ExpImg3 from 'images/svg/A-tu-Lado.svg';
import { AppContext } from 'helpers/Context';
import { getFunnelUrl } from 'helpers/FunnelRedirection';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './home.scss';
import PopupPlan from 'components/popup-plans/popup-plans';

export default function Home() {
	const { t } = useTranslation();

	const title1 = 'Tecnología innovadora tu servicio';
	const subTitle1 =
		'Gracias al uso de herramientas tecnológicas pioneras y al smart dispatch system, Connect te garantiza un despacho rápido, ofreciéndote información de progreso en tiempo real.';

	const title2 = 'Cubrimos cada rincón del territorio';
	const subTitle2 =
		'Nuestra red de colaboradores puede atenderte donde quiera que estés: están a tu disposición en tu hogar o en la carretera.';
	const title3 = 'A tu lado en todo momento';
	const subTitle3 =
		'En Connect, seguridad, transparencia y velocidad van de la mano. Con los tiempos de respuestas más rápidos del mercado, asistencia policial e información de tracking y de chófer, te garantizamos que nunca estarás solo.';
	const nuestrosClienteJson = {};

	const { branch, identify, setIdentifyStatus } = useContext(AppContext);
	const linkTo = getFunnelUrl(branch);

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PopupPlan></PopupPlan>
			<div className="App">
				<Helmet>
					<title>Connect Assistance</title>
					<meta
						name="description"
						content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
					/>
					<meta
						property="og:description"
						content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
					/>
				</Helmet>
				<SlideContent title={t('slideTitle')} subTitle={t('slideSubTitle')} />
				<SliderClients />

				<div className="experiencia-connect">
					<OrangeTitle className="ctr">La experiencia Connect</OrangeTitle>
					<ExperienciaConnect title={title1} subTitle={subTitle1} reverse={false} img={ExpImg1} />
					<ExperienciaConnect title={title2} subTitle={subTitle2} reverse={true} img={ExpImg2} />
					<ExperienciaConnect title={title3} subTitle={subTitle3} reverse={false} img={ExpImg3} />
					<Link to={{ pathname: linkTo }} className="button ctr" target="_top">
						Suscríbete ahora
					</Link>
				</div>

				<NuestrosClientes data={nuestrosClienteJson} />

				<Servicios />
				<TrabajaNosotros />
			</div>
		</>
	);
}
