import api from './Api';

const contactService = async (data: any) => {
	return await api.post('/utils/sendContactForm', data);
};

const funnelForm = async (data: any) => {
	return await api.post('/utils/sendFunnelForm', data);
};

export { contactService, funnelForm };
