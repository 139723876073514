import React from 'react';
import './modal.scss';
import CloseBtn from '../../images/svg/close-btn.svg';

const Modal = (props: any) => {
	const showHideClassName = props.show ? 'modal display-block' : 'modal display-none';

	return (
		<div className={showHideClassName}>
			<section className="modal-main">
				{!props.readOnly && <img onClick={props.handleClose} className="modal-close-btn" src={CloseBtn} alt="" />}
				<div className="modal-title">{props.children[0]}</div>
				<div className="modal-line"></div>
				<div
					className="modal-info"
					dangerouslySetInnerHTML={{
						__html: props.children[1],
					}}
				></div>
			</section>
		</div>
	);
};

export default Modal;
