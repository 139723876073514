import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

interface IProps {
	children: ReactNode;
	center: ReactNode;
}
const Main: React.FC<IProps> = ({ children }) => {
	// To give our external frontend contractor more power to
	// 	override CSS without changing React component structure
	// 	we add this class top-level which he can use to override
	// 	styles on a per-page basis.
	const { location } = useHistory();
	const normalized ='-' + location.pathname.split('/')[1];
	
	return <main className={normalized}>{children}</main>;
};

export default Main;
