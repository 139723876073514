import * as constants from '../helpers/Constants';

const getBranch = (data: string) => {
	let branch = '';

	switch (data) {
		case 'pr':
			branch = 'Puerto Rico';
			break;
		case 'cr':
			branch = 'Costa Rica';
			break;
		case 'pty':
			branch = 'Panama';
			break;
		case 'co':
			branch = 'Colombia';
			break;

		default:
			break;
	}

	return branch;
};

const getAccountName = (branch: string) => {
	return (
		{
			pr: 'Connect Assistance PR',
			cr: 'Connect Assistance CR',
			co: 'Connect Asistance CO',
		}[branch] || ''
	);
};

const getAccountIdByBranch = (branch) => {
	return (
		{
			pr: constants.ACCOUNT_ID,
			pty: constants.ACCOUNT_ID_PTY,
			cr: constants.ACCOUNT_ID_CR,
			co: constants.ACCOUNT_ID_CO,
			'Puerto Rico': constants.ACCOUNT_ID,
			'Costa Rica': constants.ACCOUNT_ID_CR,
			Panama: constants.ACCOUNT_ID_PTY,
			Colombia: constants.ACCOUNT_ID_CO,
		}[branch] || constants.ACCOUNT_ID
	);
};

const getVehicleFormCountry = (branch: string, flagMany: boolean = false, capitalize: boolean = false) => {
	return (capitalize ? 'M' : 'm') + (branch === 'cr' ? 'otocicleta' : 'otora') + (flagMany ? 's' : '');
};

const getBranchUrl = (branch: string) => {
	return (
		{
			pr: constants.urlPR,
			cr: constants.urlCR,
			pry: constants.urlPTY,
			co: constants.urlCO,
		}[branch] || constants.urlPR
	);
};

export { getBranch, getVehicleFormCountry, getAccountName, getAccountIdByBranch, getBranchUrl };
