declare global {
	interface Window {
		_learnq: any;
	}
}

const trackProfileActivityCard = async (dataFunnel: any) => {
	window._learnq.push([
		'track',
		'Added to Cart',
		{
			id: dataFunnel.step1.plan,
			autoQty: dataFunnel.step1.cobertura.auto,
			motoQty: dataFunnel.step1.cobertura.hogar,
			homeQty: dataFunnel.step1.cobertura.motora,
			mensualAuto: dataFunnel.step1.pago === 'mensual' ? dataFunnel.total.autoMensualPrice : 0,
			mensualMotora: dataFunnel.step1.pago === 'mensual' ?  dataFunnel.total.motoMensualPrice : 0,
			mensualHogar: dataFunnel.step1.pago === 'mensual' ?  dataFunnel.total.homeMensualPrice : 0,
			anualAuto: dataFunnel.step1.pago === 'anual' ? dataFunnel.total.autoAnualPrice : 0,
			anualMotora: dataFunnel.step1.pago === 'anual' ? dataFunnel.total.motoAnualPrice : 0,
			anualHogar: dataFunnel.step1.pago === 'anual' ? dataFunnel.total.homeAnualPrice : 0,
		},
	]);
};

const trackProfileActivityCheckout = async (data: any) => {
	window._learnq.push([
		'track',
		'Started Checkout',
		{
			// Product array
			id: data.step1.plan,
			autoQty: data.step1.cobertura.auto,
			motoQty: data.step1.cobertura.hogar,
			homeQty: data.step1.cobertura.motora,
			mensualAuto: data.total.autoMensualPrice,
			mensualMotora: data.total.motoMensualPrice,
			mensualHogar: data.total.homeMensualPrice,
			anualAuto: data.total.autoAnualPrice,
			anualMotora: data.total.motoAnualPrice,
			anualHogar: data.total.homeAnualPrice,
		},
	]);
};

const trackProfileActivityPlacedOrder = async (data: any, amount: number) => {
	window._learnq.push([
		'track',
		'Placed Order',
		{
			actionField: {
				id: data.subscriptionData.stripeId,
				revenue: data.amount, // Purchase - (Pago a Conversion Exitosa)
			},
			products: [
				{
					// Product array
					id: data.step1.plan,
					autoQty: data.step1.cobertura.auto,
					motoQty: data.step1.cobertura.motora,
					homeQty: data.step1.cobertura.hogar,
					mensualAuto: data.total.autoMensualPrice,
					mensualMotora: data.total.motoMensualPrice,
					mensualHogar: data.total.homeMensualPrice,
					anualAuto: data.total.autoAnualPrice,
					anualMotora: data.total.motoAnualPrice,
					anualHogar: data.total.homeAnualPrice,
				},
			],
		},
	]);
};

export default { trackProfileActivityCard, trackProfileActivityCheckout, trackProfileActivityPlacedOrder };
