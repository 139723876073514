import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField, OutlinedInputProps, Theme, createStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import './textfield.scss';
const TextInput = (props: any) => {
	const useStylesReddit = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				typography: {
					fontFamily: 'TradeGothicLT',
				},
				border: '1px solid #999ca0',
				overflow: 'hidden',
				borderRadius: 6,
				backgroundColor: 'transparent',
				color: '#999ca0',
				transition: theme.transitions.create(['border-color', 'box-shadow']),
				'& label.Mui-focused': {
					color: '#999ca0',
				},
				'&:focused': {
					backgroundColor: 'transparent',
					borderColor: '#ca651a',
				},
				'&:hover': {
					backgroundColor: 'transparent',
					borderColor: '#ca651a',
				},
			},
		})
	);
	const classes = useStylesReddit();

	return (
		<div style={{ position: 'relative' }}>
			<TextField
				className="textfield-error"
				label={props.label}
				variant="filled"
				InputProps={{ classes, disableUnderline: true } as Partial<OutlinedInputProps>}
				{...props}
				inputRef={props.inputRef}
			/>
			{props.errorinput ? <div className="textfield-error-label">{props.errorlabel}</div> : null}
			{props.tooltip && (
				<div className="tooltip__container">
					<InfoIcon style={{ fontSize: 25 }} />
					<span className="tooltip__text">{props.tooltip}</span>
				</div>
			)}
		</div>
	);
};

export default TextInput;
