import cms from './cms';
/**
 * Function to get a plan by id

 * @param id string
 * @returns object
 * @fix post request
 */
export async function getPlanById(id: string, branch: string) {
	const response = await cms.post(`subscriptions/list/${id}/${branch}`);
	return response.data.data;
}
