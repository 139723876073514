import React from 'react';
import Loader from 'react-loader-spinner';
import './loadingDialog.scss';

const LoadingDialog = (props: any) => {
	const isLoading = props.isLoading;

	return (
		<div>
			{isLoading && (
				<div className="loadingContent">
					<Loader
						type="Puff"
						color="#00BFFF"
						height={100}
						width={100}
						timeout={300000} //3 secs
					/>
				</div>
			)}
		</div>
	);
};

export default LoadingDialog;
