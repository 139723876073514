import React, { ReactNode } from 'react';

import './container.scss';

interface IProps {
	children: ReactNode;
}

const Container: React.FC<IProps> = ({ children }) => <div className="ct-container">{children}</div>;

export default Container;
