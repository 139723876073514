import React from 'react';

import './slide.scss';

interface Props {
	title: string;
}

const ServiceSlide: React.FC<Props> = ({ title }) => {
	return (
		<div className="service-slide">
			<div className="container">
				<h2>{title}</h2>
			</div>
		</div>
	);
};

export default ServiceSlide;
