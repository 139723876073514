import React from 'react';

import './productos.scss';

import img_auto from '../../../images/home/servicios/car-icon.png';
import img_moto from '../../../images/home/servicios/moto-icon.png';
import img_home from '../../../images/home/servicios/home-icon.png';
import rightArrow from '../../../images/svg/right-arrow.svg';

import { Link } from 'react-router-dom';
import { getVehicleFormCountry } from '../../../helpers/Convertions';

interface Props {
	linkTo: string;
	branch: string;
}

const ServiceProductos: React.FC<Props> = ({ linkTo, branch }) => {
	return (
		<div className="container full-width aprende-mas">
			<div className="mobile">
				<h3 className="orange-title ctr">Conoce más</h3>
				<div className="products container">
					<Link to={branch === 'pr' ? '/servicios-shared/auto' : linkTo}>
						<img className="icon" src={img_auto} alt=""></img>
						<h4>Asistencia para auto</h4>
						<img className="arrow" src={rightArrow} alt="" height="14"></img>
					</Link>
					{branch === 'pr' && (
						<Link to={branch === 'pr' ? '/servicios-shared/motora' : linkTo}>
							<img className="icon" src={img_moto} alt=""></img>
							<h4>Asistencia para {getVehicleFormCountry(branch, true)}</h4>
							<img className="arrow" src={rightArrow} alt="" height="14"></img>
						</Link>
					)}

					{branch === 'pr' && (
						<Link to={'/servicios-shared/hogar'}>
							<img className="icon" src={img_home} alt=""></img>
							<h4>Asistencia para hogar</h4>
							<img className="arrow" src={rightArrow} alt="" height="14"></img>
						</Link>
					)}
				</div>
			</div>
			<div className="desktop">
				<div className="products desktop-grid">
					<Link to={branch === 'pr' ? '/servicios-shared/auto' : linkTo}>
						<div className="top">
							<img className="icon" src={img_auto} alt="" />
							<h4>Asistencia para autos</h4>
						</div>
						<div className="orange-title">
							Aprende Más <img className="arrow-desktop" src={rightArrow} alt="" />
						</div>
					</Link>
					{branch === 'pr' && (
						<Link to={branch === 'pr' ? '/servicios-shared/motora' : linkTo}>
							<div className="top">
								<img className="icon" src={img_moto} alt="" />
								<h4>Asistencia para {getVehicleFormCountry(branch, true)}</h4>
							</div>
							<div className="orange-title">
								Aprende Más <img className="arrow-desktop" src={rightArrow} alt="" />
							</div>
						</Link>
					)}
					{branch === 'pr' && (
						<Link to={'/servicios-shared/hogar'}>
							<div className="top">
								<img className="icon" src={img_home} alt="" />
								<h4>Asistencia en tu hogar</h4>
							</div>
							<div className="orange-title">
								Aprende Más <img className="arrow-desktop" src={rightArrow} alt="" />
							</div>
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

export default ServiceProductos;
