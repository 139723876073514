export const GTM_ID = 'GTM-TFJVRCG';
//0011Y00002Mtuxw PROD
export const ACCOUNT_ID = process.env.REACT_APP_ACCOUNT_ID ? process.env.REACT_APP_ACCOUNT_ID : '001i000001tzCcu';
export const ACCOUNT_ID_CR = process.env.REACT_APP_ACCOUNT_ID_CR
	? process.env.REACT_APP_ACCOUNT_ID_CR
	: '001i000001tzCcu';
export const ACCOUNT_ID_PTY = process.env.REACT_APP_ACCOUNT_ID_PTY
	? process.env.REACT_APP_ACCOUNT_ID_PTY
	: '001i000001tzCcu';
export const ACCOUNT_ID_CO = process.env.REACT_APP_ACCOUNT_ID_CO
	? process.env.REACT_APP_ACCOUNT_ID_CO
	: '001i000001tzCcu';
export const reviews = {};
export const dsabledButonClass = '';
export const pkStripe: string = process.env.REACT_APP_PK_STRIPE
	? process.env.REACT_APP_PK_STRIPE
	: 'pk_test_5190uUtHDk9bOOMjA7dns0vu7sAXkleiJBKscW9qOt7TdSlwMepnZsk3CtJHva6MKs4wxraMboSucd9yzBoh0aaP9003SXU0vHf';
export const pkStripeCr = process.env.REACT_APP_PK_STRIPE_CR as string;
export const restrictedCouponsMensual = process.env.REACT_APP_RESTRICTED_COUPONS_MENSUAL as string;
export const restrictedCouponsAnual = process.env.REACT_APP_RESTRICTED_COUPONS_ANUAL as string;
export const defaultBranch = process.env.REACT_APP_DEFAULT_BRANCH as string;
export const termCondiPR = process.env.REACT_APP_TERMS_PR as string;
export const termCondiCO = process.env.REACT_APP_TERMS_CO as string;
export const termCondiCR = process.env.REACT_APP_TERMS_CR as string;
export const termCondiPTY = process.env.REACT_APP_TERMS_PTY as string;
export const termCondiPRHog = process.env.REACT_APP_TERMS_PR_HOG as string;
export const urlPR = process.env.REACT_APP_URL_PR as string;
export const urlCR = process.env.REACT_APP_URL_CR as string;
export const urlPTY = process.env.REACT_APP_URL_PTY as string;
export const urlCO = process.env.REACT_APP_URL_CO as string;
