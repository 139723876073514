import React from 'react';

import './section-3.scss';

import img_auto from '../../../images/services/service-seguridad.png';

interface Props {}

const ServiceSection3: React.FC<Props> = () => {
	return (
		<div className="tu-seguridad container">
			<div className="exp-content">
				<div>
					<h2>Innovamos por tu seguridad</h2>
					<p>
						En Connect, nuestro propósito principal y lo que nos mueve hacia innovar es la voluntad de hacer que te
						sientas seguro. Mejoramos a diario nuestros servicios a través de herramientas de geolocalización en tiempo
						real, despacho inteligente y más para mantenerte siempre informado durante una emergencia.
					</p>
				</div>
				<div>
					<img className="icon desktop" src={img_auto} alt="" />
				</div>
			</div>
		</div>
	);
};

export default ServiceSection3;
