import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as MySvg } from '../images/svg/down-arrow.svg';

export const DropDownIcon = () => {
	return (
		<SvgIcon className="dropdown" fontSize="large" component={MySvg} viewBox="0 -7 10 19">
			<MySvg />
		</SvgIcon>
	);
};
