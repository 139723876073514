import React, { useContext } from 'react';

import './servicios.scss';

import Container from '../../container/container';

import CarIcon from '../../../images/home/servicios/car-icon.png';
import HomeIcon from '../../../images/home/servicios/home-icon.png';
import MotoIcon from '../../../images/home/servicios/moto-icon.png';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../helpers/Context';
import { getFunnelUrl } from '../../../helpers/FunnelRedirection';
import { getVehicleFormCountry } from '../../../helpers/Convertions';

interface Props {}

const Servicios: React.FC<Props> = () => {
	const { branch } = useContext(AppContext);
	const linkTo = getFunnelUrl(branch);
	return (
		<div className="servicios">
			<Container>
				<h1 className="orange-title ctr">Servicios</h1>
				<div className="servicios__inner">
					<div className="servicios__left ctr-mobile">
						<h2>Tenemos planes para ti y para tu compañía</h2>
						<p>
							Tanto si buscas proteger tu casa o una flota de autos, en Connect podrás conseguir planes personalizados
							que se adapten a tus necesidades, hasta el más mínimo detalle.
						</p>
						<Link to="/servicios" className="button ctr-mobile">
							Conoce Más
						</Link>
					</div>
					<div className="servicios__right">
						<Link to={{ pathname: linkTo }} target="_top">
							<div className="servicios__item auto">
								<div className="servicios__item__img">
									<img src={CarIcon} alt="Auto" />
								</div>
								<h4>Asistencia para autos</h4>
							</div>
						</Link>
						{branch === 'pr' && (
							<>
								<Link to={{ pathname: linkTo }} target="_top">
									<div className="servicios__item moto">
										<div className="servicios__item__img">
											<img src={MotoIcon} alt="Motoras" />
										</div>
										<h4>Asistencia para {getVehicleFormCountry(branch, true)}</h4>
									</div>
								</Link>
								<Link to={{ pathname: linkTo }} target="_top">
									<div className="servicios__item home">
										<div className="servicios__item__img">
											<img src={HomeIcon} alt="Hogar" />
										</div>
										<h4>Asistencia en tu hogar</h4>
									</div>
								</Link>
							</>
						)}
					</div>
				</div>
			</Container>
		</div>
	);
};

export default Servicios;
