import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from 'helpers/Context';

import { Link, useParams } from 'react-router-dom';
import './popup-plans.scss';
import { getPlanById } from 'services/plansService';
import CloseBtn from 'images/svg/close-btn.svg';
import CarIcon from 'images/home/servicios/car-icon.png';
import HomeIcon from 'images/home/servicios/home-icon.png';
import MotoIcon from 'images/home/servicios/moto-icon.png';
import { getVehicleFormCountry } from 'helpers/Convertions';
import RadioButton from 'components/inputs/radio-button/radio-button';
import Pricing from 'helpers/Pricing';
import { SummaryInformationTotals } from 'components/Summary-information-totals/Summary-information-totals';
import OrangeButton from 'components/inputs/orange-button/orange-button';

export default function PopupPlan() {
	const { branch } = useContext(AppContext);
	const year = new Date().getFullYear() - (branch === 'pr' ? 15 : 22);
	const [products, setProducts] = useState<any>(null);
	const hrefBtnMeraki = useRef(null);
	const [allProducts, setAllProducts] = useState<any>(null);
	const [periodicity, setPeriodicity] = useState<string>('Anual');
	const [popUpIsVisible, setPopUpIsVisible] = useState<boolean>(false);
	const [planItemsQuantity, setPlanItemsQuantity] = useState<number[]>([]);
	const [checkVehicle, setCheckedVehicle] = useState(true);
	const [totals, setTotals] = useState<{
		subtotal: number;
		discount: number;
		total: number;
	}>({
		subtotal: 0,
		discount: 0,
		total: 0,
	});
	const [otherTotals, setOtherTotals] = useState<{
		subtotal: number;
		discount: number;
		total: number;
	}>({
		subtotal: 0,
		discount: 0,
		total: 0,
	});
	let sub = useParams();
	useEffect(() => {
		(async () => {
			try {
				const planId = sub['plan'];
				if (planId) {
					const response = await getPlanById(planId, branch);
					setAllProducts(response);
					const dataSelects = new Array(response.length).fill(1);
					setPlanItemsQuantity([...dataSelects]);
					const productsFiltered = calculateAllPrices('Anual', dataSelects, response);
					setPeriodicityInfo([...productsFiltered.productsPeriodicityAnual.data]);
					const dataSelectsNew = new Array([...productsFiltered.productsPeriodicityAnual.data].length).fill(1);
					setPlanItemsQuantity([...dataSelectsNew]);
					setPopUpIsVisible(true);
				}
			} catch (error) {
				setPopUpIsVisible(false);
			}
		})();
	}, []);

	const setPeriodicityInfo = (
		response: {
			attributes: { periocidad: { data: { attributes: { name: string } } } };
		}[]
	) => {
		setPeriodicity(response[0].attributes.periocidad.data.attributes.name);
	};

	const getIcon = (item: string) => {
		switch (item) {
			case 'Auto':
				return CarIcon;
			case 'Moto':
				return MotoIcon;
			case 'Hogar':
				return HomeIcon;
			default:
				return CarIcon;
		}
	};

	const calculateTotal = (product: any, dataSelects: number[]) => {
		let subtotal = 0;
		let discount = 0;
		let total = 0;
		let multiplo = 1;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		product.forEach((element: any, index: number) => {
			if (element.attributes.productos.data[0].attributes.periocity_id.data.attributes.name === 'Anual') {
				multiplo = 12;
				if (dataSelects[index] <= element.attributes.productos.data[0].attributes.max_additional) {
					subtotal += element.attributes.productos.data[0].attributes.base_price * multiplo * dataSelects[index];
					discount +=
						element.attributes.productos.data[0].attributes.base_price *
						multiplo *
						dataSelects[index] *
						(element.attributes.productos.data[0].attributes.price_discount / 100);
					total += element.attributes.productos.data[0].attributes.price * dataSelects[index];
				} else {
					subtotal += element.attributes.productos.data[0].attributes.base_price * multiplo * dataSelects[index];
					discount +=
						element.attributes.productos.data[0].attributes.base_price *
						multiplo *
						dataSelects[index] *
						(element.attributes.productos.data[0].attributes.additional_discount / 100);
					total += element.attributes.productos.data[0].attributes.additional_price * dataSelects[index];
				}
			} else {
				multiplo = 1;
				if (dataSelects[index] <= element.attributes.productos.data[0].attributes.max_additional) {
					subtotal += element.attributes.productos.data[0].attributes.base_price * multiplo * dataSelects[index];
					discount +=
						element.attributes.productos.data[0].attributes.base_price *
						multiplo *
						dataSelects[index] *
						(element.attributes.productos.data[0].attributes.price_discount / 100);
					total = subtotal - discount;
				} else {
					subtotal += element.attributes.productos.data[0].attributes.base_price * multiplo * dataSelects[index];
					discount +=
						element.attributes.productos.data[0].attributes.base_price *
						multiplo *
						dataSelects[index] *
						(element.attributes.productos.data[0].attributes.additional_discount / 100);
					total += element.attributes.productos.data[0].attributes.additional_price * dataSelects[index];
				}
			}
		});
		const summaryPrices = {
			subtotal,
			discount,
			total,
		};
		return summaryPrices;
	};

	const calculateAllPrices = (item: any, itemsQuantity: number[], data: any = null) => {
		const dataAllProducts = data ? [...data] : [...allProducts];
		const productsFiltered = dataAllProducts.filter(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(itemProductsFiltered: any) => itemProductsFiltered.attributes.periocidad.data.attributes.name === item
		);
		const otherProductsFiltered = dataAllProducts.filter(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(itemProductsFiltered: any) => itemProductsFiltered.attributes.periocidad.data.attributes.name !== item
		);
		setProducts([...productsFiltered]);
		const dataPricing = calculateTotal([...productsFiltered], itemsQuantity);
		const otherDataPricing = calculateTotal([...otherProductsFiltered], itemsQuantity);
		setOtherTotals({
			...otherDataPricing,
		});
		setTotals({
			...dataPricing,
		});
		return {
			productsPeriodicityAnual: { data: productsFiltered, prices: dataPricing },
			productsPeriodicityMensual: {
				data: otherProductsFiltered,
				prices: otherDataPricing,
			},
		};
	};

	const handlePlanItemsQuantity = (value: number | string, index?: number) => {
		if (
			(planItemsQuantity[index as number] <= 0 && value === -1) ||
			(planItemsQuantity[index as number] >= 5 && value === 1)
		) {
			return;
		}
		planItemsQuantity[index as number] = planItemsQuantity[index as number] + Number(value);
		setPlanItemsQuantity([...planItemsQuantity]);
		const dataPricing = calculateTotal(products, planItemsQuantity);
		calculateAllPrices(periodicity, planItemsQuantity);
	};

	const handlePeriodicity = (item: 'Anual' | 'Mensual') => {
		setPeriodicity(item);
		calculateAllPrices(item, planItemsQuantity);
	};

	const navigateToMeraki = () => {
		const dataProductUrl = products.map((product: any) => product.id);
		const dataQuantityUrl = planItemsQuantity.join(',');
		const dataUrl = `${process.env.REACT_APP_MERAKI_FRONT}/checkout?planId=${dataProductUrl.join(
			','
		)}&country=${branch}&quantity=${dataQuantityUrl}`;
		(hrefBtnMeraki.current as any).href = dataUrl;
		(hrefBtnMeraki.current as any).click();
	};

	return (
		<>
			{popUpIsVisible && (
				<div className="popup__container swal2-show">
					<div className="popup__content">
						<img className="popup__btn__close" src={CloseBtn} alt="" onClick={() => setPopUpIsVisible(false)} />
						<h3 className="cobertura-h3">Escoge el tipo de plan que necesitas</h3>
						{branch !== 'co' && (
							<p className="cobertura-p text-left">
								Recibe descuentos pagando anualmente y añadiendo 2 o más propiedades
							</p>
						)}
						<div className="cobertura-select-content popup__max--width">
							{products &&
								products.map((product: any, index: number) => (
									<>
										<div className="cobertura-select-items" key={`cobertura-select-items${index}`}>
											<div className="cobertura-select-item-type-content">
												<img className="cobertura-auto" src={getIcon(product.attributes.alias_product_type)} alt="" />
											</div>
											<div className="cobertura-select-item-type-label">
												<p>{product.attributes.alias_product_type}</p>
											</div>
											<div className="cobertura-select-item-qty-content">
												<Link to="#" onClick={() => handlePlanItemsQuantity(-1, index)} className="qty-icon minus-icon">
													<svg
														width="10"
														height="10"
														viewBox="0 0 10 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<title>-</title>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
															fill="#E87722"
														/>
													</svg>
												</Link>
												<div className="cobertura-qty-label">{planItemsQuantity[index]}</div>
												<Link to="#" onClick={() => handlePlanItemsQuantity(1, index)} className="qty-icon plus-icon">
													<svg
														width="10"
														height="10"
														viewBox="0 0 10 10"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect x="4.375" width="1.25" height="10" rx="0.625" fill="#E87722" />
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M0.625 5.625C0.279822 5.625 0 5.34518 0 5V5C0 4.65482 0.279822 4.375 0.625 4.375L9.375 4.375C9.72018 4.375 10 4.65482 10 5V5C10 5.34518 9.72018 5.625 9.375 5.625L0.625 5.625Z"
															fill="#E87722"
														/>
													</svg>
												</Link>
											</div>
										</div>
										{product.attributes.alias_product_type === 'Auto' ? (
											<div className="cobertura-select-checkbox-container">
												<div className="title">
													{planItemsQuantity[index] === 1
														? `El auto es del ${year} o más nuevo`
														: `Los autos son del ${year} o más nuevos`}
												</div>
											</div>
										) : (
											''
										)}
										{product.attributes.alias_product_type === 'Moto' ? (
											<div className="cobertura-select-checkbox-container">
												<div className="title">
													{planItemsQuantity[index] === 1
														? `La ${getVehicleFormCountry(branch)} es del ${year} o más nueva`
														: `Las ${getVehicleFormCountry(branch, true)} son del ${year} o más nuevas`}
												</div>
											</div>
										) : (
											''
										)}
									</>
								))}
						</div>
						<div className="popup__totals popup__max--width ">
							<SummaryInformationTotals values={totals} branch={branch} isHidden={true}></SummaryInformationTotals>
						</div>
						<div className="cobertura-payment-content popup__max--width not__margin--top">
							<RadioButton
								handleClick={() => handlePeriodicity('Mensual')}
								className={`${periodicity === 'Mensual' ? 'active' : ''}`}
								price={`${
									periodicity === 'Mensual'
										? Pricing.formatPrice(totals.total, branch, 2)
										: Pricing.formatPrice(otherTotals.total, branch, 2)
								}`}
								priceDiscount={`${
									periodicity === 'Mensual'
										? Pricing.formatPrice(totals.discount, branch, 2)
										: Pricing.formatPrice(otherTotals.discount, branch, 2)
								}`}
								checkedStatus={periodicity === 'Mensual' ? true : false}
								label={'Pago Mensual'}
							></RadioButton>
							<RadioButton
								handleClick={() => handlePeriodicity('Anual')}
								className={`${periodicity === 'Anual' ? 'active' : ''}`}
								price={`${
									periodicity === 'Anual'
										? Pricing.formatPrice(totals.total, branch, 2)
										: Pricing.formatPrice(otherTotals.total, branch, 2)
								}`}
								checkedStatus={periodicity === 'Anual' ? true : false}
								priceDiscount={`${
									periodicity === 'Anual'
										? Pricing.formatPrice(totals.discount, branch, 2)
										: Pricing.formatPrice(otherTotals.discount, branch, 2)
								}`}
								label={'Pago Anual'}
							>
								<div className="active-indicator"></div>
							</RadioButton>
						</div>
						<OrangeButton handleClick={navigateToMeraki} className="popup__button button">
							Comprar
						</OrangeButton>
						<a ref={hrefBtnMeraki} target="_top" rel="noopener noreferrer" href={''} style={{ display: 'none' }}></a>
					</div>
				</div>
			)}
		</>
	);
}
