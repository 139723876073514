// import React from "react";
import { hydrate, render } from 'react-dom';
import i18n from './helpers/i18n';
import { I18nextProvider } from 'react-i18next';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import React from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router } from 'react-router-dom';

const rootElement = document.getElementById('root') as HTMLElement;
if (rootElement.hasChildNodes()) {
	hydrate(
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>,
		rootElement
	);
} else {
	render(
		<I18nextProvider i18n={i18n}>
			<App />
		</I18nextProvider>,
		rootElement
	);
}

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

// ReactDOM.render(
// 	<Router>
// 		<App />
// 	</Router>,
// 	document.getElementById('root')
// );
// serviceWorker.register();
// registerServiceWorker();
