import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import './productos-shared.scss';

import ServicesHeroProducts from 'components/services/hero-products/hero-products';
import Container from 'components/container/container';
import OrangeTitle from 'components/orange-title/orange-title';
import ServiceNuestrosClientes from 'components/services/nuestros-clientes/nuestros-clientes';

import AutoData from 'data/auto.json';
import MotoraData from 'data/motora.json';
import HogarData from 'data/hogar.json';
import AutoImg from 'images/services/service-auto.png';
import MotoraImg from 'images/services/service-motora.png';
import HogarImg from 'images/services/service-hogar.png';
import ServiceSubscribete from 'components/services/subscribete/subscribete';
import useHideOnScrolled from 'helpers/UseHideScroll';
import { AppContext } from 'helpers/Context';
import { getFunnelUrl } from 'helpers/FunnelRedirection';
import { Helmet } from 'react-helmet';
import Pricing from 'helpers/Pricing';

export default function ProductosShared() {
	const { branch, identify, setIdentifyStatus } = useContext(AppContext);
	const [isCheckboxStatus, setCheckboxStatus] = useState(true);

	const handleInputChange = (event: any) => {
		!isCheckboxStatus ? setCheckboxStatus(true) : setCheckboxStatus(false);
	};

	useEffect(() => {
		if (!identify) {
			window.analytics.identify();
			setIdentifyStatus(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	let params: any = useParams();
	let data: any = {};
	let heroImg = '';
	let pricing = 0;

	const linkTo = getFunnelUrl(branch);
	const dataCountPricing = {
		auto: 1,
		moto: 1,
		home: 1,
		pago: 'mensual',
		branch: branch,
	};
	const allPricing = Pricing.calculate(dataCountPricing);
	switch (params.section) {
		case 'auto':
			data = AutoData;
			heroImg = AutoImg;
			pricing = allPricing.autoMensualPrice;
			break;

		case 'motora':
			data = MotoraData;
			heroImg = MotoraImg;
			pricing = allPricing.motoMensualPrice;
			break;
		case 'hogar':
			data = HogarData;
			heroImg = HogarImg;
			pricing = allPricing.homeMensualPrice;
			break;
	}
	const nuestrosClienteJson = {};

	const hidden = useHideOnScrolled();

	return (
		<div className="App">
			<Helmet>
				<title>Consigue tu plan hoy - Connect Assistance</title>
				<meta
					name="description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
				<meta
					property="og:description"
					content="Connect Assistance es la compañía #1 en Asistencia en Carretera y en el Hogar en Puerto Rico, Panamá, Costa Rica y Colombia"
				/>
			</Helmet>
			<div className="App-header ">
				<div>
					{hidden && (
						<div className="mobile">
							<div
								className={`productos-shared-header-sub-container productos-shared-header-sub-container-white productos-shared-header-sub-container-fixed`}
							>
								<div className="productos-shared-header-sub-left">
									<div className="productos-shared-header-sub-label">Consigue tu plan hoy</div>
								</div>
								<div className="productos-shared-header-sub-right">
									<Link to={{ pathname: linkTo }} className="btn" target="_top">
										<div className="btn">Suscríbete</div>
									</Link>
								</div>
							</div>
						</div>
					)}

					<ServicesHeroProducts
						title={branch === 'cr' ? data.hero_title_two : data.hero_title}
						backgroundImg={heroImg}
					/>
					{params.section === 'hogar' && branch !== 'pr' ? (
						<h1>Pronto mas Información</h1>
					) : (
						<div>
							<ServiceSubscribete branch={branch} section={data.section} linkTo={linkTo} pricing={pricing} />
							<Container>
								<div className="productos-shared-container">
									<div className="productos-shared-section-2">
										<div>
											<h2>{data.h2_title}</h2>
											<p>{branch === 'cr' ? data.h2_info_two : data.h2_info}</p>
										</div>
									</div>
									<div className="productos-shared-section-3">
										<div>
											<OrangeTitle className="productos-shared-section-orange-title">¿Cómo funciona?</OrangeTitle>
											<h2>La experiencia Connect</h2>
											<div className="productos-shared-section-3-items">
												<div className="productos-shared-section-3-item">
													<div className="productos-shared-section-3-item-number desktop">1</div>
													<div className="productos-shared-section-3-item-period">
														<div className="productos-shared-section-3-item-period-square"></div>
														<div className="productos-shared-section-3-item-period-line"></div>
													</div>
													<div className="productos-shared-section-3-item-info">
														<h3>{data.experienca_1_title}</h3>
														<p>{data.experienca_1_info}</p>
													</div>
												</div>
												<div className="productos-shared-section-3-item">
													<div className="productos-shared-section-3-item-number desktop">2</div>
													<div className="productos-shared-section-3-item-period">
														<div className="productos-shared-section-3-item-period-square"></div>
														<div className="productos-shared-section-3-item-period-line"></div>
													</div>
													<div className="productos-shared-section-3-item-info">
														<h3>{data.experienca_2_title}</h3>
														<p>{data.experienca_2_info}</p>
													</div>
												</div>
												<div className="productos-shared-section-3-item">
													<div className="productos-shared-section-3-item-number desktop">3</div>
													<div className="productos-shared-section-3-item-period">
														<div className="productos-shared-section-3-item-period-square"></div>
													</div>
													<div className="productos-shared-section-3-item-info">
														<h3>{data.experienca_3_title}</h3>
														<p>{data.experienca_3_info}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Container>
							<div className="productos-shared-section-4">
								<Container>
									<div className="productos-shared-container">
										<div className="productos-shared-section-4-container">
											<div>
												<OrangeTitle>¿Qué incluye tu plan?</OrangeTitle>
												<h2>Un plan a tu medida</h2>
												<p>
													Los planes de Connect están adaptados a las necesidades de cada consumidor y, por ello, te
													ofrecemos diferentes maneras de contratarlas. Elige un plan de pago mensual o da el salto a un
													plan anual, con el que ahorrarás hasta un 50% en tu plan. Además, puedes unir hasta 5 planes
													de asistencia diferentes para maximizar tu ahorro.
												</p>
												<div className="productos-shared-section-4-container-row">
													<div className="productos-shared-section-4-container-col">
														{branch === 'co' ? (
															<ul
																dangerouslySetInnerHTML={{
																	__html: data.listLeftPlan_co,
																}}
															/>
														) : (
															<ul
																dangerouslySetInnerHTML={{
																	__html: branch === 'cr' ? data.listLeftPlan_cr : data.listLeftPlan,
																}}
															/>
														)}
													</div>
													<div className="productos-shared-section-4-container-col">
														{branch === 'co' ? (
															<ul
																dangerouslySetInnerHTML={{
																	__html: data.listRightPlan_co,
																}}
															/>
														) : (
															<ul
																dangerouslySetInnerHTML={{
																	__html: branch === 'cr' ? data.listRightPlan_cr : data.listRightPlan,
																}}
															/>
														)}
													</div>
												</div>
												<div className="productos-shared-section-4-plans-container">
													<div className="productos-shared-section-4-plans-title">
														<div className="productos-shared-wrap-collabsible">
															<input
																id="productos-shared-collapsible"
																className="toggle"
																type="checkbox"
																onChange={handleInputChange}
																checked={isCheckboxStatus}
															/>
															<label
																htmlFor="productos-shared-collapsible"
																className="productos-shared-lbl-toggle mas-info uppercase productos-shared-section-4-plans-title"
															>
																Detalles del Plan
															</label>
															<div className="productos-shared-collapsible-content">
																<div className="productos-shared-section-4-plans-legal">
																	{branch === 'pr' && (
																		<div dangerouslySetInnerHTML={{ __html: data.detallesDelPlan }} />
																	)}
																	{branch === 'cr' && (
																		<div dangerouslySetInnerHTML={{ __html: data.detallesDelPlan_cr }} />
																	)}
																	{branch === 'co' && (
																		<div dangerouslySetInnerHTML={{ __html: data.detallesDelPlan_co }} />
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Container>
							</div>
						</div>
					)}
				</div>
				<ServiceNuestrosClientes data={nuestrosClienteJson} />
			</div>
		</div>
	);
}
