import React, { useState, useContext } from 'react';

import './resumen.scss';

import OrangeButton from '../../../components/inputs/orange-button/orange-button';
import { FunnelData } from '../../../interfaces/FunnelInterface';
import { AppContext } from '../../../helpers/Context';
import ResumenSlide from '../../../components/ResumenSlide/ResumenSlide';
import { getVehicleFormCountry } from '../../../helpers/Convertions';
import Pricing from 'helpers/Pricing';
interface IProps {
	className?: string;
	show?: String;
	data: FunnelData;
	price?: number;
	pago?: string;
	modalClick?: any;
}

const FunnelResumen: React.FC<IProps> = ({ className, show, data, price, pago, modalClick }) => {
	price = Number(price);
	const { branch } = useContext(AppContext);
	const [isCheckboxStatus, setCheckboxStatus] = useState(false);
	const handleInputChange = (event: any) => {
		!isCheckboxStatus ? setCheckboxStatus(true) : setCheckboxStatus(false);
	};

	const hideModall = () => {
		setCheckboxStatus(false);
	};

	return (
		<div>
			<div className="mobile">
				<div className="wrap-collabsible">
					<input
						id="collapsible"
						className="toggle"
						type="checkbox"
						onChange={handleInputChange}
						checked={isCheckboxStatus}
					/>
					<label htmlFor="collapsible" className="lbl-toggle mas-info uppercase orange">
						Ver detalles del plan
					</label>
					<div className="collapsible-content">
						<div className="content-inner">
							<div className={`funnel-resumen-content `}>
								<div className="funnel-resumen-top-div">
									{data?.step1.cobertura.auto > 0 && (
										<div className="funnel-resumen-h4">
											{branch === 'co' ? 'Asistencia vial para auto ' : 'Asistencia en carretera para auto '}(
											{data?.step1.cobertura.auto})
										</div>
									)}
									{data?.step1.cobertura.motora > 0 && (
										<div className="funnel-resumen-h4">
											Asistencia en carretera para {getVehicleFormCountry(branch)} ({data?.step1.cobertura.motora})
										</div>
									)}
									{branch === 'pr' && data?.step1.cobertura.hogar > 0 && (
										<div className="funnel-resumen-h4">Asistencia en el hogar ({data?.step1.cobertura.hogar})</div>
									)}

									<div className="funnel-resumen-pago">
										Total a pagar <span>${Pricing.formatPrice(price, branch, 2)}</span>
										{data?.step3.discountAmount && data?.step3.discountAmount > 0 ? '*' : ''}
									</div>
								</div>
								<ResumenSlide data={data} modalClick={modalClick}></ResumenSlide>

								<div className="legend-renovacion">
									{data?.step3.discountAmount && data?.step3.discountAmount > 0
										? '*Renovación automática al precio original'
										: ''}
								</div>

								<OrangeButton handleClick={hideModall} className="full-width">
									Cerrar
								</OrangeButton>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="desktop">
				<div className="funnel-resumen-content">
					<div className="funnel-resumen-top-div">
						<div className="mas-info uppercase orange">¿Qué incluye tu plan?</div>
						{data?.step1.cobertura.auto > 0 && (
							<div className="funnel-resumen-h4 margin-top-12">
								{branch === 'co' ? 'Asistencia vial para auto ' : 'Asistencia en carretera para auto '}(
								 {data?.step1.cobertura.auto})
							</div>
						)}
						{data?.step1.cobertura.motora > 0 && (
							<div className="funnel-resumen-h4">
								Asistencia en carretera para {getVehicleFormCountry(branch)} ({data?.step1.cobertura.motora})
							</div>
						)}
						{branch === 'pr' && data?.step1.cobertura.hogar > 0 && (
							<div className="funnel-resumen-h4">Asistencia en el hogar ({data?.step1.cobertura.hogar})</div>
						)}

						<div className="funnel-resumen-pago">
							<span>${Pricing.formatPrice(price, branch, 2)} </span>
							pago {data?.step1.pago || pago}
						</div>
					</div>

					<ResumenSlide data={data} modalClick={modalClick}></ResumenSlide>
				</div>
			</div>
		</div>
	);
};

export default FunnelResumen;
