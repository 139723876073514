import api from './Api';
const getSubscription = async (id) => {
	return await api.get(`/sales/gSubscription/${id}`);
};

const getCupon = async (id: string, branch: string, plan: string) => {
	const dataCupon = { couponId: id, branch: branch, plan };
	const data = await api.post(`/sales/gCupon`, dataCupon);
	return data.data;
};

const getPresale = async (uuid: string) => {
	const data = await api.post(`/sales/getPresale`, { ps: uuid });
	return data.data;
};

export { getSubscription, getCupon, getPresale };
