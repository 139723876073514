import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation, withRouter } from 'react-router-dom';
import 'App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Main from 'components/main/main';
import Home from 'views/home';
import FunnelCobertura from 'views/funnel/cobertura/cobertura';
import FunnelInfo from 'views/funnel/info/info';
import { useTranslation, withTranslation } from 'react-i18next';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import FunnelPago from 'views/funnel/pago/pago';
import FunnelConfirmacion from 'views/funnel/confirmacion/confirmacion';
import FunnelPerfilVehiculo from 'views/funnel/perfil-vehiculo/perfil-vehiculo';
import FunnelPerfilHogar from 'views/funnel/perfil-hogar/perfil-hogar';
import FunnelFinalizar from 'views/funnel/finalizar/finalizar';
import Services from 'views/services/services';
import ProductosShared from 'views/services/productos-shared/productos-shared';
import { AppContext } from 'helpers/Context';
import { useStatus } from 'helpers/AppHook';
import Contacto from 'views/contacto/contacto';
import FAQ from 'views/faq/faq';
import Sobre from 'views/sobre';
import { GlobalProvider } from 'context/GlobalState';
import { ToastContainer } from 'react-toastify';
import FunnelPerfil from 'views/funnel/perfil/perfil';
import Terminos from 'views/terminos/terminos';
import CambiosEnPrecio from 'views/cambiosEnPrecio/cambiosEnPrecio';
import PtyFunel from 'views/funnel/pty-funnel/pty-funnel';
import CrFunnel from 'views/funnel/cr-funnel/cr-funnel';
import CoFunnel from 'views/funnel/co-funnel/co-funnel';
import Politicas from 'views/politicas/politicas';
import TagManager from 'react-gtm-module';
import * as constants from 'helpers/Constants';
import HeliosAppPrivacyPolicy from 'views/terminos/helios-app-privacy-policy';
import { Helmet } from 'react-helmet';
import PoliticasHabeasData from 'views/politicasHabeasData/politicasHabeasData';
import PoliticasSeguridadInformatica from 'views/politicasSeguridadInformatica/politicasSeguridadInformatica';
import whatsapp from 'images/svg/whatsapp.svg';

const tagManagerArgs = {
	gtmId: constants.GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const _ScrollToTop = (props: any) => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return props.children;
};

const ScrollToTop = withRouter(_ScrollToTop);

declare global {
	interface Window {
		analytics: any;
	}
}

const usePageViews = (props: any) => {
	const { pathname } = useLocation();
	useEffect(() => {
		window.analytics.page('Loaded a Page');
	}, [pathname]);
	return props.children;
};

const PageView = withRouter(usePageViews);

function App() {
	const { t } = useTranslation();
	const showStatus = useStatus();
	const getSiteVerification = () => {
		return (
			{
				'beta.cra.pr': '',
				'connect.cr': 'qcGTny5pLOI0zT2tDRG3vhY-7ep0Tqb1Fu3GMYk5pKg',
				'connect.com.pa': 'PQHPWIKKE30OWMjcy1FkgpXQb4IkHMiHrpXJyifnc_I',
				'connect.com.co': 'LQ6OU-Tre2XaI-cE5MtuYWKZh7xkezdDuSeIXWorhDE',
			}[window.location.hostname] || ''
		);
	};

	return (
		<div className="container__app">
			<AppContext.Provider value={showStatus}>
				<Helmet>
					<meta name="google-site-verification" content={getSiteVerification()} />
					<meta name="theme-color" content="#000000" />
				</Helmet>
				<ToastContainer />
				<GlobalProvider>
					<Router>
						<PageView>
							<ScrollToTop>
								<Main center>
									<Header />
									<Switch>
										<Route exact path="/arma-tu-plan/:plan">
											<Home />
										</Route>
										<Route exact path="/">
											<Home />
										</Route>
										<Route path="/cobertura/">
											<FunnelCobertura />
										</Route>
										<Route path="/info/:id/:coupon?">
											<FunnelInfo />
										</Route>
										<Route path="/info/">
											<FunnelInfo />
										</Route>
										<Route path="/pago/">
											<FunnelPago />
										</Route>
										<Route path="/confirmacion/:id/:notification">
											<FunnelConfirmacion />
										</Route>
										<Route path="/perfil/:id/:notification">
											<FunnelPerfil />
										</Route>
										<Route path="/perfil-vehiculo">
											<FunnelPerfilVehiculo />
										</Route>
										<Route path="/perfil-hogar">
											<FunnelPerfilHogar />
										</Route>
										<Route path="/finalizar">
											<FunnelFinalizar />
										</Route>
										<Route exact path="/servicios">
											<Services />
										</Route>
										<Route exact path="/servicios-shared/:section">
											<ProductosShared />
										</Route>
										<Route exact path="/contacto">
											<Contacto />
										</Route>
										<Route exact path="/faq">
											<FAQ />
										</Route>
										<Route exact path="/sobre-nosotros">
											<Sobre />
										</Route>
										<Route exact path="/terminos-y-condiciones/:branch">
											<Terminos />
										</Route>
										<Route exact path="/pty-funnel">
											<PtyFunel />
										</Route>
										<Route exact path="/cr-funnel">
											<CrFunnel />
										</Route>
										<Route exact path="/co-funnel">
											<CoFunnel />
										</Route>
										<Route exact path="/politicas">
											<Politicas />
										</Route>
										<Route exact path="/politicas-habeas-data">
											<PoliticasHabeasData />
										</Route>
										<Route exact path="/politicas-seguridad-informatica">
											<PoliticasSeguridadInformatica />
										</Route>
										<Route exact path="/helios-driver-app/privacy-policy">
											<HeliosAppPrivacyPolicy />
										</Route>
										<Route exact path="/cambios-en-precio-2021/:branch">
											<CambiosEnPrecio />
										</Route>
									</Switch>
									<Footer />
								</Main>
							</ScrollToTop>
						</PageView>
					</Router>
				</GlobalProvider>
			</AppContext.Provider>
			{(!['/cobertura', ' /info', '/pago', '/finalizar'].includes(window.location.pathname) ||
				!window.location.pathname.indexOf('perfil')) && (
				<a target="_blank" rel="noopener noreferrer" href={t('phoneLinkWhatsapp')} className="whatsapp__container">
					<img src={whatsapp} alt="whatsapp Icon" />
					Chat with us
				</a>
			)}
		</div>
	);
}

export default withTranslation()(App);
