const isBlackFriday = new Date('2022-12-01T00:00:00').getTime() > new Date().getTime();

const getFunnelUrl = (branch: String) => {
	switch (branch) {
		case 'pr':
			return isBlackFriday ? 'https://lp.connect.pr/bf22' : '/cobertura';
		case 'cr':
			return isBlackFriday ? 'https://lp.connect.cr/bf22' : '/cobertura';
		case 'pty':
			return '/pty-funnel';
		case 'co':
			return '/cobertura';

		default:
			return '/cobertura';
	}
};

export { getFunnelUrl };
