import React, { useContext } from 'react';
import './primary-content.scss';

import Container from '../container/container';
import { Link } from 'react-router-dom';
import { AppContext } from '../../helpers/Context';
import { getFunnelUrl } from '../../helpers/FunnelRedirection';

interface Props {
	title: string;
	subTitle: string;
}

const SlideContent: React.FC<Props> = ({ title, subTitle }) => {
	const { branch } = useContext(AppContext);
	const linkTo = getFunnelUrl(branch);

	return (
		<div className="banner ctr" >
			<Container>
				<div className="slide-content">
					<h1>{title}</h1>
					<p className="slide-p-padding">{subTitle}</p>
					<Link to={{ pathname: linkTo }} className="button ctr" target="_top">
						Suscríbete
					</Link>
					<Link to="/servicios" className="mas-info underline">
						Más Información
					</Link>
				</div>
			</Container>
		</div>
	);
};

export default SlideContent;
