import React from 'react';

import './trabaja-nosotros.scss';
import Container from '../../container/container';
import OrangeTitle from '../../orange-title/orange-title';

interface Props {}

const TrabajaNosotros: React.FC<Props> = () => {
	return (
		<div className="trabaja ctr-mobile">
			<Container>
				<div className="trabaja__cols">
					<div className="trabaja__left">
						<OrangeTitle>Trabaja con nosotros</OrangeTitle>
						<h2 className="white tn-h2">Únete al equipo Connect</h2>
						<a
							rel="noopener noreferrer"
							target="_blank"
							href="https://jobs.lever.co/connectassistance"
							className="button ctr-mobile"
						>
							Ver Plazas Disponibles
						</a>
					</div>
					<div className="trabaja__right"></div>
				</div>
			</Container>
			<div className="trabaja__bg"></div>
		</div>
	);
};

export default TrabajaNosotros;
