import { constants } from 'buffer';
import { getAccountIdByBranch, getAccountName } from 'helpers/Convertions';
import { utmData } from 'interfaces/FunnelInterface';
import api from './Api';

const createLead = async (branch: string, utm: utmData) => {
	console.log('branch', branch);

	return await api.post('/sales/cSalesFunel', {
		branch: branch,
		utm: utm,
		accountId: getAccountIdByBranch(branch.toLocaleLowerCase()),
		accountName: getAccountName(branch.toLocaleLowerCase()),
		platform: 'CRA Website',
	});
};

const updateLead = async (data: any, utm: utmData) => {
	return await api.post('/sales/uSalesFunel', { _id: data._id, fields: data, utm: utm, platform: 'CRA Website' });
};

export { createLead, updateLead };
