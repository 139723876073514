import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const mapMarker = require('../../images/svg/logo-pin.svg');
const icon = { url: mapMarker, scaledSize: { width: 40, height: 40 } };

const MyMapComponent = withGoogleMap((props: any) => (
	<GoogleMap defaultZoom={17} center={{ lat: props.lat, lng: props.lng }}>
		{props.isMarkerShown && <Marker icon={icon} position={{ lat: props.lat, lng: props.lng }} />}
	</GoogleMap>
));

const Map = (props: any) => {
	return (
		<div style={{ height: '386px', width: '100%' }}>
			<MyMapComponent
				isMarkerShown
				lat={props.lat}
				lng={props.lng}
				containerElement={<div style={{ height: `100%` }} />}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		</div>
	);
};

export default Map;
