import TagManager from 'react-gtm-module';

const planPage = (selectedPlan: string) => {
	const tagManagerArgs = {
		dataLayer: {
			page: 'cobertura',
			ecommerce: {
				detail: {
					actionField: {
						list: 'Plan Page',
					},
					products: [
						{
							id: selectedPlan,
						},
					],
					checkout: undefined,
				},
			},
			event: 'detail',
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

const shoppingCardStep1 = (data: any) => {
	const tagManagerArgs = {
		dataLayer: {
			gtm: {
				historyChangeSource: undefined,
			},
			page: 'info',
			ecommerce: {
				detail: undefined,
				checkout: {
					actionField: {
						step: 1,
					},
					products: [
						{
							// Product array
							id: data.step1.plan,
							autoQty2: data.step1.cobertura.auto,
							autoQty: data.step1.cobertura.auto,
							motoQty: data.step1.cobertura.hogar,
							homeQty: data.step1.cobertura.motora,
							mensualAuto: data.total.autoMensualPrice,
							mensualMotora: data.total.motoMensualPrice,
							mensualHogar: data.total.homeMensualPrice,
							anualAuto: data.total.autoAnualPrice,
							anualMotora: data.total.motoAnualPrice,
							anualHogar: data.total.homeAnualPrice,
						},
					],
				},
			},
			event: 'checkout',
		},
	};

	TagManager.dataLayer(tagManagerArgs);
};

const shoppingCardStep2 = (data: any) => {
	const tagManagerArgs = {
		dataLayer: {
			page: 'pago',
			ecommerce: {
				checkout: {
					actionField: {
						step: 2,
					},
					products: [
						{
							// Product array
							id: data.step1.plan,
							autoQty: data.step1.cobertura.auto,
							motoQty: data.step1.cobertura.hogar,
							homeQty: data.step1.cobertura.motora,
							mensualAuto: data.total.autoMensualPrice,
							mensualMotora: data.total.motoMensualPrice,
							mensualHogar: data.total.homeMensualPrice,
							anualAuto: data.total.autoAnualPrice,
							anualMotora: data.total.motoAnualPrice,
							anualHogar: data.total.homeAnualPrice,
						},
					],
				},
			},
			event: 'checkout',
		},
	};
	TagManager.dataLayer(tagManagerArgs);
};

const purchase = (data: any, amount: any) => {
	const tagManagerArgs = {
		dataLayer: {
			page: 'confirmacion',
			ecommerce: {
				checkout: undefined,
				purchase: {
					actionField: {
						id: data.subscriptionData.stripeId,
						revenue: amount, // Purchase - (Pago a Conversion Exitosa)
					},
					products: [
						{
							// Product array
							id: data.step1.plan,
							autoQty: data.step1.cobertura.auto,
							motoQty: data.step1.cobertura.hogar,
							homeQty: data.step1.cobertura.motora,
							mensualAuto: data.total.autoMensualPrice,
							mensualMotora: data.total.motoMensualPrice,
							mensualHogar: data.total.homeMensualPrice,
							anualAuto: data.total.autoAnualPrice,
							anualMotora: data.total.motoAnualPrice,
							anualHogar: data.total.homeAnualPrice,
						},
					],
				},
			},
			event: 'purchase',
		},
	};
	TagManager.dataLayer(tagManagerArgs);
};

export default { planPage, shoppingCardStep1, shoppingCardStep2, purchase };
