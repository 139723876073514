import React, { ReactNode } from 'react';

import './orange-title.scss';

interface IProps {
	children: ReactNode;
	className?: string;
}

const OrangeTitle: React.FC<IProps> = ({ children, className }) => (
	<div className={`orange-title ${className}`}>{children}</div>
);

export default OrangeTitle;
