import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationPR from '../data/i18next/pr.json';
import translationCR from '../data/i18next/cr.json';
import translationPTY from '../data/i18next/pty.json';
import translationCO from '../data/i18next/co.json';

const resources = {
	pr: {
		translation: translationPR,
	},
	pty: {
		translation: translationPTY,
	},
	cr: {
		translation: translationCR,
	},
	co: {
		translation: translationCO,
	},
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'pr',
		fallbackLng: 'pr', // use en if detected lng is not available

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
