import React from 'react';
import { defaultBranch } from './Constants';

export interface AppContext {
	showStatus: boolean;
	setShowStatus: (currentUrl: boolean) => void;
	setBranchStatus: (currentUrl: string) => void;
	branch: string;
	identify: boolean;
	setIdentifyStatus: (currentUrl: boolean) => void;
}

export const URL_DEFAULT_VALUE = {
	showStatus: true,
	setShowStatus: () => {},
	setBranchStatus: () => {},
	branch: window.location.search ? window.location.search.split('=')[1] : defaultBranch,
	identify: false,
	setIdentifyStatus: () => {},
};

export const AppContext = React.createContext<AppContext>(URL_DEFAULT_VALUE);
