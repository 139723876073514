const identifyUser = (dataUser: any) => {
	const data = {
		name: dataUser.step2.nombre + ' ' + dataUser.step2.apellido,
		email: dataUser.step2.email,
		plan: dataUser.step1.plan,
		phone: dataUser.step2.telefono,
	};
	window.analytics.identify(data);
};

export default { identifyUser };
