import axios from 'axios';
import cms from './cms';

const getProvincia = async () => {
	//No se utiliza un baseUrl ya que es un webservice de CSM
	return await axios.get('catalogs/provinces?code=CRC', {
		baseURL: 'https://connectservicesapp-quality.azurewebsites.net/api/v1/',
	});
};

const getDeparments = async () => {
	const departments = await cms.get(`/country/departments/CO`);
	return departments.data.data.attributes.data;
};

const getMunicipalities = async (departmentId: string) => {
	const municipalities = await cms.get(`country/municipality/CO/${departmentId}`);
	return municipalities.data.data.attributes.data;
};

export { getProvincia, getDeparments, getMunicipalities };
