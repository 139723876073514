import styled from 'styled-components';

const duration = '0.3s';
const timingEase = 'cubic-bezier(0.51, 0.92, 0.24, 1)';
const dotSize = 0.3;
const dotMargin = 0.5;
interface LayoutProps {
	dots?: any;
	getPos?: any;
	leftDotPosition?: any;
	rightDotPosition?: any;
}

export const FunnelBg = styled.div`
	padding-bottom: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	@media only screen and (max-width: $screen-md-min) {
		padding-top: 60px;
	}
`;

export const FunnelSlide = styled.div`
	position: relative;
	overflow: hidden;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
`;

export const FunnelSlideContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;

export const FunnelSlideContainerCenter = styled.div`
	display: flex;
	flex: 1 1 100%;
`;

export const FunnelSlideContainerCenterCenter = styled.div`
	display: flex;
	flex: 1 1 90%;
	align-items: center;
`;

export const FunnelSlideContainerBottom = styled.div`
	flex: 1 1 27%;
	display: flex;
	align-items: center;
	margin-left: 30px;
	margin-right: 30px;

	@media only screen and (max-width: $screen-md-min) {
		align-items: flex-end;
		padding-bottom: 60px;
	}
`;

export const FunnelSlideContainerBottomLeft = styled.div`
	display: flex;
	flex: 1 1 5%;
`;

export const FunnelSlideContainerBottomCenter = styled.div`
	display: flex;
	flex: 1 1 90%;
	align-items: center;
	justify-content: center;
	@media only screen and (max-width: $screen-md-min) {
		height: 35px;
	}
`;

export const FunnelSlideContainerDots = styled.div`
	display: flex;
	position: absolute;
`;

export const FunnelSlideContainerBottomRight = styled.div`
	display: flex;
	flex: 1 1 5%;
	justify-content: flex-end;
`;

export const FunnelSlideContainerCenterLeft = styled.div`
	display: flex;
	flex: 1 1 5%;
	padding-left: 10px;
	@media only screen and (max-width: $screen-md-min) {
		padding-left: 0px;
	}
`;

export const FunnelSlideContainerCenterRight = styled.div`
	display: flex;
	flex: 1 1 5%;
	justify-content: flex-end;
	padding-right: 10px;
	@media only screen and (max-width: $screen-md-min) {
		padding-right: 0px;
	}
`;

export const FunnelSlideContainerSlides = styled.div<LayoutProps>`
	width: ${(props) => props.dots * 100}%;
	position: relative;
	height: 100%;
	transition: transform ${duration} ${timingEase};
	will-change: transform;
	transform: translateX(-${(props) => (100 / props.dots) * props.getPos}%);
`;

export const FunnelSlideContainerSlide = styled.div<LayoutProps>`
	float: left;
	width: ${(props) => 100 / props.dots}%;
	height: 100%;
`;

export const FunnelSlideContainerDot = styled.div<LayoutProps>`
	display: block;
	margin: 0 ${dotMargin}em;
	width: auto;
	height: ${dotSize}em;
	background: #535353;
	border-radius: 100px;
	position: absolute;
	left: ${(props) => props.leftDotPosition}em;
	right: ${(props) => props.rightDotPosition}em;
`;
