import React, { ReactNode } from 'react';

import './orange-button.scss';

interface IProps {
	children: ReactNode;
	className?: string;
	classNameBtn?: string;
	handleClick?: any;
	type?: string;
	isDisabled?: boolean;
}

const OrangeButton: React.FC<IProps> = ({ children, className, classNameBtn, handleClick, type, isDisabled }) => (
	<button
		type={!type ? 'submit' : 'button'}
		onClick={handleClick}
		disabled={isDisabled}
		className={`button button--orange ${className} ${classNameBtn ? classNameBtn : ''}`}
	>
		{children}
	</button>
);

export default OrangeButton;
