import React from 'react';

import './slider-clients.scss';

import forbes from '../../../images/forbes.png';
import endi from '../../../images/endi.png';
import inc from '../../../images/inc.png';
import cb from '../../../images/cb.png';
import metro from '../../../images/metro.png';

interface Props {}

const SliderClients: React.FC<Props> = () => {
	return (
		<div className="cs-content">
			<ul className="cs-ul" id="horizontal-list">
				<li className="cs-li">
					<img src={endi} alt="list item 1" />
				</li>
				<li>
					<img src={inc} alt="list item 1" />
				</li>
				<li>
					<img src={forbes} alt="list item 1" />
				</li>
				<li>
					<img src={cb} alt="list item 1" />
				</li>
				<li>
					<img src={metro} alt="list item 1" />
				</li>
			</ul>
		</div>
	);
};

export default SliderClients;
