import React from 'react';
import './Summary-information-totals.scss';
import Pricing from 'helpers/Pricing';

export interface IPropsSummaryTotals {
	values: { subtotal: number; discount: number; total: number };
	branch: string;
	isHidden?: boolean;
}

export const SummaryInformationTotals = ({ values, branch, isHidden }: IPropsSummaryTotals) => {
	return (
		<div className="summary__information__container">
			<div className="summary__information__content">
				<div className="summary__information__label">Subtotal</div>
				<div className="summary__information__value line-through">
					$ {Pricing.formatPrice(values.subtotal, branch, 2)}
				</div>
			</div>
			{values.discount ? (
				<div className="summary__information__content">
					<div className="summary__information__label">Descuento</div>
					<div className="summary__information__value">$ {Pricing.formatPrice(values.discount, branch, 2)}</div>
				</div>
			) : (
				''
			)}

			{!isHidden && (
				<div className="summary__information__content">
					<div className="summary__information__label bold not--margin">Total</div>
					<div className="summary__information__value bold not--margin">
						$ {Pricing.formatPrice(values.total, branch, 2)}
					</div>
				</div>
			)}
		</div>
	);
};
